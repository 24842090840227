import React, { useRef, useState } from "react";

import { GenericTable, Input, toastMessage, Button, tCell, Dialog, ActionMenu, Dropdown, Checkbox } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import { useCheckpoints, useOrgs, useProductCategories, useUserDetail, useUserPermissions } from "../../services/hooks";
import { fakeFileInput, formatDate } from "../../services/functions";
import { useAddProductCategoryMutation, useDeleteProductCategoryMutation, useUpdateProductCategoryMutation, useUploadProductCategoryMutation } from "../../api/vmsSlice";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { Grid } from "@mui/material";
import { GenericstatsV2 } from "../utils/Genericstats";

const SAMPLE_EXCEL_PATH = "/sample_excels/prod_cat_sample.xlsx";
const EXCEL_TYPE = "product_category";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const ProductCategories = () => {
	const { userPermissions, isFetched } = useUserPermissions();

	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const [isModal, setIsModal] = useState(false);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const onModalOpen = () => setIsModal(true);
	const updateDataRef = useRef(null);
	const [filterApplied, setFilterApplied] = useState(false);
	const filterFields = [
		{
			inputType: "textfield",
			id: "2",
			name: "productCategory",
			type: "text",
			label: "Categories",
			placeholder: "Categories",
			readOnly: false,
			error: "",
			value: "",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { productCategories, count, isLoading, message, getProdCats } = useProductCategories(filterRef.current);

	const [handleDelete] = useDeleteProductCategoryMutation();
	let actionHandlers = {};
	if (userPermissions?.updateProductCategory?.value) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = productCategories[id];
			setIsModal(true);
		};
	}
	if (userPermissions?.deleteProductCategory?.value) {
		actionHandlers.delete = async (id) => {
			// console.log(id, "delete");
			const data = productCategories[id];
			const payload = { deleteId: data._id };
			const {
				data: { error, message },
			} = await handleDelete(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				onUpdateSuccess();
				toastMessage(true, message);
			}
		};
	}
	let header = ["S.no", "Product Category", "Created On"];
	if (userDetail?.userType == "superAdmin") header.push("Org Name");
	if (userPermissions?.updateProductCategory?.value || userPermissions?.deleteProductCategory?.value) {
		header.push("Action");
	}
	const rows =
		productCategories?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.productCategory), tCell(formatDate(d.created_on)));

			if (userDetail?.userType == "superAdmin") row.push(tCell(d.orgName));

			if (userPermissions?.updateProductCategory?.value || userPermissions?.deleteProductCategory?.value) {
				row.push(<div style={{ paddingLeft: "0.5rem" }}>
					<ActionMenu id={i} handlers={actionHandlers} />
				</div>);
			}
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getProdCats(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getProdCats(filterRef.current);
	};

	const onUpdateSuccess = () => {
		getProdCats(filterRef.current);
	};
	const [handleUploadExcel] = useUploadProductCategoryMutation();
	const onBulkExcelUpload = () => {
		fakeFileInput(async (formData) => {
			formData.append("type", EXCEL_TYPE);
			// const payload = {
			// 	file,
			// 	type: EXCEL_TYPE,
			// };
			const {
				data: { error, message },
			} = await handleUploadExcel(formData);
			if (error) {
				toastMessage(false, message);
			} else {
				toastMessage(true, message);
				onUpdateSuccess();
			}
		});
	};

	const handleFilterChange = (filter) => {
		if (filter?.productCategory != "" && Object.keys(filter).length > 0) {
			// console.log("filter on change-device", filter);
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getProdCats(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.productCategory === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log("without-filter on change-device", filter);
			getProdCats(defaultFilter);
			setFilterApplied(false);
		}
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getProductCategory?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<Grid container spacing="0.5rem">
				<Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
					<h1 className="title">Product Categories</h1>
				</Grid>
				<Grid item xl={6} lg={6} md={7} sm={12} xs={12}>
					<Grid container spacing="0.5rem">
						{userPermissions?.addProductCategory?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onModalOpen} text="Add Product Category" style={buttonClasses.lynkitOrangeEmpty} />
							</Grid>
						) : null}
						{userPermissions?.addProductCategory?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onBulkExcelUpload} text="Bulk Upload" style={buttonClasses.lynkitOrangeFill} />
							</Grid>
						) : null}
						{userPermissions?.addProductCategory?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<a href={SAMPLE_EXCEL_PATH} download>
									<Button text="Download Sample" style={buttonClasses.lynkitBlackFill} />
								</a>
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>

			<hr className="bgg" />
			<GenericstatsV2 statsFor={"productCategories"} />
			<div className="cust-row flex-algn-cent">
				<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			</div>
			<GenericTable
				header={header}
				rows={rows}
				pageCount={count}
				pageNo={filterRef.current?.page_no}
				limit={filterRef.current?.pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>
			{isModal && <AddProductCategory open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
		</div>
	);
};

export default ProductCategories;

const defaultForm = {
	productCategory: "",
	updateId: undefined,
	orgId: undefined,
	isApprovalRequired: false,
	checkpoints: [],
	prefix: "",
	productCategoryId: "",
};

function fillUpdateData(data) {
	return {
		productCategory: data.productCategory,
		updateId: data._id,
		orgId: data.orgId,
		isApprovalRequired: data.checkpoints.length > 0 ? true : !!data.isApprovalRequired,
		checkpoints: data.checkpoints || [],
		prefix: data.prefix || "",
		productCategoryId: data.productCategoryId || "",
	};
}

function AddProductCategory({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const orgValue = orgOptions?.find((_) => _.value == formData.orgId);
	const filterRef = useRef({ orgId: updateData?.orgId });
	const { checkpointOptions } = useCheckpoints(filterRef.current);
	// console.log('formData', formData, updateData)
	let checkpointValues = checkpointOptions?.filter((chk) => formData?.checkpoints?.length > 0 && formData.checkpoints?.find((fchk) => fchk?.value == chk.value || fchk == chk.value));
	// console.log({ checkpointOptions });
	// console.log({ checkpointValues });
	const handleInput = (e) => {
		let { name, value, checked, type } = e.target;
		value = type == "checkbox" ? checked : value;
		// console.log(name, value, type, checked);
		let extraValue = {};
		if (name == "isApprovalRequired" && !value) {
			extraValue = { checkpoints: [] };
		}
		if (name == "isApprovalRequired" && formData?.orgId) {
			filterRef.current = { ...filterRef.current, orgId: formData?.orgId || "" };
		}
		setFormData({ ...formData, [name]: value, ...extraValue });
	};
	const onDropdownChange = (option, name) => {
		let value = Array.isArray(option) ? option : option?.value || "";
		handleInput({ target: { name, value } });
		if (name == "orgId" && formData.isApprovalRequired) {
			filterRef.current = { ...filterRef.current, orgId: option?.value || "" };
		}
	};

	const [handleAdd, { isLoading: isAdding }] = useAddProductCategoryMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateProductCategoryMutation();
	const handleFormSubmit = async () => {
		const payload = { ...formData, orgName: orgValue?.label, checkpoints: formData.checkpoints?.map((_) => _.value) };
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};
	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";
		if (!formData.productCategory) error.productCategory = "Product Category is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		setError({});
		onClose();
	};

	// console.log('formData', formData);
	return (
		<Dialog
			size="xs"
			open={open}
			handleClose={handleClose}
			handleSubmit={handleFormValidation}
			title={updateData ? "Update Product Category" : "Add Product Category"}
			isSubmitDisabled={isAdding || isUpdating}
		>
			<form>
				<div className="form-inputs mt-2">
					{userDetail?.userType == "superAdmin" ? (
						<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} error={error.orgId} required />
					) : null}
					<Input label="Product Category" placeholder="Enter Product Category" name="productCategory" value={formData.productCategory} onChange={handleInput} error={error.productCategory} required />
					<Input label="Prefix" placeholder="Enter Prefix" name="prefix" value={formData.prefix} onChange={handleInput} />
					<Input label="Product Category Id" placeholder="Enter Product Category Id" name="productCategoryId" value={formData.productCategoryId} onChange={handleInput} />
					<Checkbox
						label="Send Approval Request from Checkpoint(s)"
						id="approvalRequired"
						name="isApprovalRequired"
						checked={formData.isApprovalRequired}
						onChange={handleInput}
						size="medium"
					/>
					{formData.isApprovalRequired ? (
						<Dropdown
							id="checkpoints"
							name="checkpoints"
							label="Select Checkpoints"
							onChange={onDropdownChange}
							options={checkpointOptions}
							value={checkpointValues}
							error={error.checkpoints}
							multiple
						/>
					) : null}
					
				</div>
			</form>
		</Dialog>
	);
}
