import { Grid } from "@mui/material";

function MainContent({ title, children, panel }) {
	return (
		<div className="contentpanel">
			<Grid container spacing="0.5rem">
				<Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
					<h1 className="title">{title}</h1>
				</Grid>
				<Grid item xl={6} lg={6} md={7} sm={12} xs={12}>
					<Grid container spacing="0.5rem" justifyContent={"flex-end"}>
						{panel}
					</Grid>
				</Grid>
			</Grid>

			<hr className="bgg" style={{ marginTop: "0.5rem" }} />
			{children}
		</div>
	);
}

export default MainContent;
