import React, { useEffect, useState, useRef, useMemo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { autocompleteTheme, buttonClasses } from "../utils/theme";
import PreviewFile from "../utils/PreviewFile";

import {
	Typography,
	TextField,
	FormControl,
	FormLabel,
	MenuItem,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	FormGroup,
	Grid,
	Autocomplete,
	Tooltip,
	InputAdornment,
	IconButton,
} from "@mui/material";

import theme, { inputClasses, textClasses } from "../utils/theme";
import { getShortText } from "../../services/functions";
import { AddIcon, CloseIcon, CheckIcon } from "../../services/icons";
import { Button, Input } from "../utils";

export const CustomInput = ({ component, index = null, autoFillOnFocus, errors, fetchSourceDropdownData, onDropdownChange, onInputChange, onQuickAddSave }) => {
	const inputRefs = useRef([]);
	const inputFileRef = useRef();
	const [focusField, setFocusField] = useState(0);
	useEffect(() => {
		const { dynamic, dontFetch, api, apiMethod, values } = component;
		if (!dontFetch && dynamic && api && apiMethod && !values?.length) {
			fetchSourceDropdownData(component);
		}
	}, []);

	const [isQuickAddActive, setIsQuickAddActive] = useState(false);
	const [quickAddValue, setQuickAddValue] = useState("");

	const handleQuickAddActive = () => {
		setIsQuickAddActive(true);
	};
	const handleQuickAddCancel = () => {
		setIsQuickAddActive(false);
		setQuickAddValue("");
	};
	const handleQuickAddChange = (e) => {
		const { name, value } = e.target;
		setQuickAddValue(value);
	};
	const handleQuickAddSubmit = () => {
		onQuickAddSave(component, quickAddValue, handleQuickAddCancel);
	};

	const convertDocsBase64 = (file) => {
		if (file) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					const fileReader = new FileReader();
					fileReader.readAsDataURL(file);
					fileReader.onload = () => {
						resolve(fileReader.result);
					};
					fileReader.onerror = (error) => {
						reject(error);
					};
				}, 400);
			});
		} else {
		}
	};

	let basicProps = {
		size: "small",
		fullWidth: true,
		type: component.type,
		multiline: component.type == "textarea",
		rows: component.type == "textarea" ? 3 : 1,
		label: `${component.label}${component.required ? " *" : ""}`,
		placeholder: component.placeholder,
		onFocus: autoFillOnFocus ? autoFillOnFocus : () => {},
		error: errors && errors[component._id] ? true : false,
		InputLabelProps: { shrink: true },
	};

	if (component.type == "number") {
		basicProps.onWheel = (event) => event.target.blur();
		basicProps.onKeyDown = (e) => {
			if (["e", "E", "+", "-"].includes(e.key)) {
				e.preventDefault();
			} else if (e.key == "0" && (e.target.value == "" || e.target.value[0] == "0")) {
				e.preventDefault();
			}
		};
		basicProps = {
			...basicProps,
		};
	}
	if (component.type == "date") {
		basicProps.InputLabelProps = { shrink: true };
		basicProps = {
			...basicProps,
		};
	}
	if (component.type == "file") {
		basicProps.focused = true;
		basicProps.onChange = async (e) => {
			const file = await convertDocsBase64(e.target.files[0]);
		};
	}

	if (component.type != "file") {
		basicProps = {
			...basicProps,
		};
	}

	if (["input", "number", "textarea", "date", "file", "searchTags", "text"].indexOf(component.type) >= 0) {
		if (component.multiple && Array.isArray(component.value)) {
			const changeParent = (value) => {
				onInputChange({ target: { value } });
			};
			const handleAdd = (index) => {
				changeParent([...component.value, ""]);
				setFocusField(index + 1);
			};

			const handleFocus = (index) => {
				setFocusField(index);
			};
			const handleRemove = (index) => {
				const newVal = [...component.value];
				newVal.splice(index, 1);
				changeParent(newVal);
				inputRefs.current.splice(index, 1);
				setFocusField(newVal.length - 1);
			};
			const handleChange = (e, i) => {
				const newVal = [...component.value];
				newVal.splice(i, 1, e.target.value);
				changeParent(newVal);
			};
			return (
				<FormControl variant="outlined" sx={{ width: "100%", marginTop: "-15px" }}>
					<Typography sx={{ ...textClasses.boldText, fontSize: "12px", lineHeight: "15px" }}>{`${component.label}${component.required ? " *" : ""}`}</Typography>

					{component.value?.map((val, i) => {
						return (
							<TextField
								autoFocus={i == 0}
								key={i}
								size="small"
								variant="outlined"
								inputRef={(ref) => (inputRefs.current[i] = ref)}
								sx={{ ...inputClasses.textField, marginBottom: "0.5rem" }}
								onFocus={() => handleFocus(i)}
								InputProps={{
									sx: { ...inputClasses.textField },
									readOnly: component?.readonly || false,
									endAdornment: (
										<InputAdornment position="end">
											{component.value.length > 1 ? (
												<IconButton aria-label="remove item" onClick={() => handleRemove(i)} edge="end">
													<CloseIcon style={{ fill: "var(--icon-color)" }} />
												</IconButton>
											) : null}
											{i == component.value.length - 1 ? (
												<IconButton aria-label="add item" onClick={() => handleAdd(i)} edge="end">
													<AddIcon style={{ fill: "var(--icon-color)" }} />
												</IconButton>
											) : null}
										</InputAdornment>
									),
								}}
								type={component.type}
								value={val || ""}
								onChange={(e) => {
									handleChange(e, i);
								}}
								onKeyDown={(e) => (e.key == "Enter" || e.keyCode == "13" ? handleAdd(i) : "")}
								label=""
								placeholder=""
							/>
						);
					})}
				</FormControl>
			);
		} else {
			return (
				<>
					<TextField
						{...basicProps}
						onChange={onInputChange}
						value={component.type != "file" ? (index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value || "") : null}
						sx={{ ...inputClasses.textField }}
						InputProps={{
							sx: { ...inputClasses.textField },
							readOnly: component?.readonly || false,
						}}
						inputRef={(ref) => (inputFileRef.current = ref)}
					/>
					{component.type === "file" ? component?.value ? <PreviewFile file={component.value} /> : null : null}
				</>
			);
		}
	} else if (component.type == "select") {
		const handleDropdownChange = (event, selectedOption, reason, details) => {
			if (component?.multiselect) {
				onDropdownChange(selectedOption.map((ele) => ele.value) || []);
			} else {
				onDropdownChange(selectedOption?.value == undefined ? "" : selectedOption.value);
			}
		};
		const options = getOptionsFromValues(component);
		let value;
		// eslint-disable-next-line
		value = useMemo(() => {
			if (component?.multiselect) {
				return options?.filter((chk) => [...component.value]?.find((fchk) => fchk == chk.value));
			} else {
				return options?.find((dl) => dl.value == (index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value || "")) || null;
			}
		}, [component?.value, options]);
		
		return (
			<div style={{ display: "grid", gridTemplateColumns: "1fr auto auto", gap: "0.5rem", alignItems: "center" }}>
				{isQuickAddActive ? (
					<Input label={component.label} name="quickAddValue" value={quickAddValue} onChange={handleQuickAddChange} />
				) : (
					<FormControl fullWidth={basicProps.fullWidth} size={basicProps.size} error={basicProps.error}>
						<ThemeProvider theme={autocompleteTheme}>
							<Autocomplete
								id="gmap-demo"
								sx={{ ...inputClasses.themeBoundary }}
								onFocus={basicProps.onFocus}
								options={options}
								multiple={component?.multiselect}
								autoComplete
								size="small"
								includeInputInList
								filterSelectedOptions
								noOptionsText={component.isSearching ? "Searching..." : "No Data Found"}
								value={value}
								onChange={handleDropdownChange}
								isOptionEqualToValue={(option, value) => {
									return (option?.value == "" && value == "") || option?.value == value?.value;
								}}
								onInputChange={(e, newInputValue, reason) => {
									//e.type = "click" when option is selected
									if (e?.type == "change" || reason == "clear") {
										onInputChange({ target: { value: newInputValue } });
									}
								}}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											{...basicProps}
											label={`${component.label}${component.required ? " *" : ""}`}
											fullWidth
											sx={{ ...inputClasses.themeBoundary, ...inputClasses.textField }}
										/>
									);
								}}
								renderOption={({ key, ...props }, option, { selected }) => {
									return (
										<MenuItem value={option.value} sx={{ justifyContent: "space-between", color: "var(--text-color)" }} key={key + props["data-option-index"]} {...props}>
											<Tooltip sx={{ width: "50px" }} title={option.label}>
												<Typography>{getShortText(option.label, 21)}</Typography>
											</Tooltip>
											{selected ? <CheckIcon color="info" /> : null}
										</MenuItem>
									);
								}}
							/>
						</ThemeProvider>
					</FormControl>
				)}
				{component.quickAdd ? (
					isQuickAddActive ? (
						<>
							<Button text="Save" style={{ ...buttonClasses.lynkitOrangeFill }} onClick={handleQuickAddSubmit} />
							<Button text="Cancel" style={{ ...buttonClasses.lynkitBlackFill }} onClick={handleQuickAddCancel} />
						</>
					) : (
						<Button text="Add" style={{ ...buttonClasses.lynkitGreenFill }} onClick={handleQuickAddActive} />
					)
				) : null}
			</div>
		);
	} else if (component.type == "radio") {
		const innerComp = (
			<RadioGroup row sx={{ mt: 1 }} value={index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value || ""} onChange={onInputChange}>
				{component.values &&
					component.values.map((op, i) => {
						return (
							<FormControlLabel
								key={i}
								label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
								control={<Radio value={op.value} size="small" sx={{ "&.Mui-checked": { color: theme.themeOrange } }} />}
							/>
						);
					})}
			</RadioGroup>
		);
		if (component.multiline != null && component.multiline == false) {
			return (
				<Grid container justifyContent={"left"} alignItems="center">
					{innerComp}
				</Grid>
			);
		}
		return (
			<div style={{ ...inputClasses.shadowField, border: `1px solid ${basicProps.error ? "#e74c3c" : "#D9D9D9"}`, width: "100%", padding: "10px" }}>
				<FormControl fullWidth size="small">
					<FormLabel
						sx={{
							color: "rgb(0 0 0 / 60%) !important",
							"& .MuiFormLabelRoot.MuiFocused": {
								color: "#F77200",
							},
						}}
					>
						{component.label}
					</FormLabel>
					{innerComp}
				</FormControl>
			</div>
		);
	} else if (component.type == "checkbox") {
		const innerComp = (
			<FormGroup row sx={{ mt: 1 }}>
				{component.values &&
					component.values.map((op, i) => {
						return (
							<FormControlLabel
								key={i}
								label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
								control={
									<Checkbox
										checked={index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value == op.value}
										onClick={() => onInputChange({ target: { value: op.value } })}
										size="small"
										sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
									/>
								}
							/>
						);
					})}
			</FormGroup>
		);
		if (component.multiline != null && component.multiline == false) {
			return (
				<Grid container justifyContent={"left"} alignItems="center">
					{innerComp}
				</Grid>
			);
		}
		return (
			<div style={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
				<FormControl fullWidth size="small">
					<FormLabel
						sx={{
							color: "rgb(0 0 0 / 60%) !important",
							"& .MuiFormLabelRoot.MuiFocused": {
								color: "#F77200",
							},
						}}
					>
						{component.label}
					</FormLabel>
					{innerComp}
				</FormControl>
			</div>
		);
	}
};

function getOptionsFromValues(field) {
	const { values, apiBindingKey, apiBindingValue } = field;
	const options = values?.map((el) => ({ label: el[apiBindingKey || "label"], value: el[apiBindingValue || "value"], tripId: el.trip_counter }));
	return options;
}
