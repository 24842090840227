import React, { useRef, useState } from 'react';
import { useGetApprovalListingQuery, useUpdateApprovalMutation, useGetTripcheckpointMutation } from '../../api/vmsSlice';
import { useUserDetail, useUserPermissions } from '../../services/hooks';
import { GenericTable, tCell, ActionMenu, toastMessage } from '../utils';
import { Dialog } from '../utils';
import { formatDate, findDeepValue, capitalizeCamelCase, removeSpecialChars } from '../../services/functions';
import './Approval.css'
import { Navigate } from "react-router-dom/dist";
import { Checkbox, Button } from '../utils';
import { buttonClasses } from '../utils/theme';
import DynamicFormRender from '../Dynamic/DynamicForm';

const defaultFilter = {
    pagesize: 10,
    page_no: 1,
};

export default function ApprovalIndex() {
    const { userPermissions, isFetched } = useUserPermissions();
    const { userDetail } = useUserDetail();
    const filterRef = useRef(defaultFilter);
    const { page_no, pagesize } = filterRef.current;
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [moveToList, setMoveToList] = useState([]);
    const [approvalAction, setApprovalAction] = useState("");
    const [approvalTemplateId, setApprovalTemplateId] = useState([]);
    const [approvalPayload, setApprovalPayload] = useState({})
    const [updateTicketTrigger] = useUpdateApprovalMutation();
    const [getTripcheckpointTrigger] = useGetTripcheckpointMutation();
    const { data, isLoading, refetch, isFetching } = useGetApprovalListingQuery(filterRef.current, { refetchOnMountOrArgChange: true });
    let { data: approvalList, tableHeader, count, } = data || { data: [] };

    let header = ["S.No", ...(tableHeader || []).map((_) => _.label), "Action"];
    let actionHandlers = {};
    actionHandlers.approve = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;
        const payload = {
            "reference_id": [_id],
            "formData": { "remarks": 'done' },
            "approvalStatus": "Approved",
            "module": "accessManagement"
        }
        if (approvalList[index].approvalTemplateID) {
            setApprovalAction("Approve");
            setApprovalPayload([payload])
            setApprovalTemplateId([approvalList[index].approvalTemplateID])
        } else {
            updateTicketTrigger({ ...payload }).unwrap()
                .then((res) => {
                    // console.log(res);
                    if (res.error) {
                        toastMessage(false, res.message);
                    } else {
                        toastMessage(true, `Trip is updated successfully`);
                    }
                })
                .catch((err) => {
                    console.log('error updating approval', err);
                })
        }

        // console.log('approve clicked', payload, entry);
    };
    actionHandlers.reject = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;

        const payload = {
            "reference_id": [_id],
            "approvalStatus": "Rejected",
            "isRejected": true,
            "module": "accessManagement"
        }
        if (approvalList[index].approvalTemplateID) {
            setApprovalAction("Reject");
            setApprovalPayload([payload])
            setApprovalTemplateId([approvalList[index].approvalTemplateID])
        } else {
            updateTicketTrigger({ ...payload }).unwrap()
                .then((res) => {
                    // console.log(res);
                    if (res.error) {
                        toastMessage(false, res.message);
                    } else {
                        toastMessage(true, `Trip is updated successfully`);
                    }
                })
                .catch((err) => {
                    console.log('error updating approval', err);
                })
        }

        // console.log('reject clicked', payload);
    };
    actionHandlers.hold = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;
        const payload = {
            "reference_id": [_id],
            "approvalStatus": "Hold", // will follow the format moveTo-checkpointStatus,
            "module": "accessManagement"
        }
        if (approvalList[index].approvalTemplateID) {
            setApprovalAction("Hold");
            setApprovalPayload([payload])
            setApprovalTemplateId([approvalList[index].approvalTemplateID])
        } else {
            updateTicketTrigger({ ...payload }).unwrap()
                .then((res) => {
                    // console.log(res);
                    if (res.error) {
                        toastMessage(false, res.message);
                    } else {
                        toastMessage(true, `Trip is updated successfully`);
                    }
                })
                .catch((err) => {
                    console.log('error updating approval', err);
                })
        }

        // console.log('hold clicked', payload);
    };
    actionHandlers.moveTo = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;


        getTripcheckpointTrigger({ "tripId": _id }).unwrap()
            .then((res) => {
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    const checkpointList = res.data;
                    setMoveToList(checkpointList.map((checkpoint) => ({
                        label: checkpoint.displayName,
                        value: checkpoint.checkpointNumber
                    })));
                }
            })
            .catch((err) => {
                toastMessage(false, 'Error fetching trip checkpoint data');
                console.log('error fetching trip checkpoint data',
                    err);
            })


        // console.log('moveto clicked', entry);
    };

    const onModalClose = () => {
        setApprovalTemplateId([]);
        setApprovalPayload({})
        setSelectedTickets([])
        setApprovalAction("")
    };

    const handleChildMenuClick = (item, index) => {
        const entry = approvalList[item.index];
        const { _id } = entry;
        const payload = {
            "reference_id": [_id],
            "approvalStatus": `moveTo-${item.label}`, // will follow the format moveTo-checkpointStatus,
            "toCheckpoint": item.value,
            "module": "accessManagement"
        }

        console.log('moveTo-secondmenu clicked', payload);

        if (approvalList[index].approvalTemplateID) {
            setApprovalAction("Move to");
            setApprovalPayload([payload])
            setApprovalTemplateId([approvalList[index].approvalTemplateID])
        } else {
            updateTicketTrigger({ ...payload }).unwrap()
                .then((res) => {
                    // console.log(res);
                    if (res.error) {
                        toastMessage(false, res.message);
                    } else {
                        toastMessage(true, `Trip is updated successfully`);
                    }
                })
                .catch((err) => {
                    console.log('error updating approval', err);
                })
        }
    }

    const handleMultiAction = (action) => {
        let payload;
        let selectedTicket = [];
        let approvalPayload = [];
        let approvalTemplateId = [];
        selectedTickets.forEach((el, index) => {
            const temp = approvalList.find((e) => e._id === el)
            if (temp.approvalTemplateID) {
                let payloadd;
                if (action == 'approve') {
                    payloadd = {
                        "reference_id": [el],
                        "approvalStatus": "Approved",
                        "module": "accessManagement"
                    }
                }
                if (action == 'reject') {
                    payloadd = {
                        "reference_id": [el],
                        "approvalStatus": "Rejected",
                        "isRejected": true,
                        "module": "accessManagement"
                    }
                }
                if (action == 'hold') {
                    payloadd = {
                        "reference_id": [el],
                        "approvalStatus": "Hold", // will follow the format moveTo-checkpointStatus,
                        "module": "accessManagement"
                    }
                }
                approvalPayload.push(payloadd)
                approvalTemplateId.push(temp.approvalTemplateID)
            } else {
                selectedTicket.push(el)
            }
        })
        if (action == 'approve') {
            setApprovalAction("Approve");
            payload = {
                "reference_id": selectedTicket,
                "approvalStatus": "Approved",
                "module": "accessManagement"
            }
        }
        if (action == 'reject') {
            setApprovalAction("Reject");
            payload = {
                "reference_id": selectedTicket,
                "approvalStatus": "Rejected",
                "isRejected": true,
                "module": "accessManagement"
            }
        }
        if (action == 'hold') {
            setApprovalAction("Hold");
            payload = {
                "reference_id": selectedTicket,
                "approvalStatus": "Hold", // will follow the format moveTo-checkpointStatus,
                "module": "accessManagement"
            }
        }
        // console.log('handleMultiAction', selectedTicket, approvalPayload, approvalTemplateId, payload);
        if (selectedTicket.length > 0) {
            updateTicketTrigger({ ...payload }).unwrap()
                .then((res) => {
                    // console.log(res);
                    if (res.error) {
                        toastMessage(false, res.message);
                    } else {
                        setSelectedTickets([])
                        if (res?.additionalSuccessMessage) {
                            const { pass, fail } = CheckResponse(res.additionalSuccessMessage)
                            toastMessage(true, `${pass} Trip is updated ${fail ? `${fail} Trip is failed to update` : ``}`);
                        } else {
                            toastMessage(true, `Trip is ${res.message}`);
                        }

                    }
                })
                .catch((err) => {
                    console.log('error updating approval', err);
                })
        }
        setApprovalPayload(approvalPayload)
        setApprovalTemplateId(approvalTemplateId)

    }

    const handleTrip = (ticket) => {
        // console.log('handleTrip', ticket);
        setSelectedTickets((prev) => {
            const index = prev.findIndex((t) => t === ticket);
            if (index === -1) {
                return [...prev, ticket];
            } else {
                return prev.filter((t) => t !== ticket);
            }
        });
    }

    const rows = approvalList?.map((d, i) => {
        let row = [];
        row.push(
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Checkbox
                    disabled={(['Cancelled', 'Terminated'].includes(d.trip_status)) ? true : ["Rejected", "Approved"].includes(d.approvalStatus) ? true : false}
                    checked={selectedTickets.includes(d._id) ? true : false}
                    onChange={() => handleTrip(d._id)}
                    name={"trip_id"}
                />
                {((page_no - 1) * pagesize + i + 1)}
            </div>
        )
        for (let item of tableHeader) {
            row.push(tCell(item.type === "date" ? formatDate(findDeepValue(d, item.name.split("."))) : findDeepValue(d, item.name.split("."))));
        }
        let _action = { ...actionHandlers };
        const ActionStatus = () => {
            const term = /terminated/i;
            const canc = /cancelled/i;
            if (term.test(d.trip_status) || canc.test(d.trip_status)) {
                if (/approved/i.test(d.approvalStatus)) {
                    return <span className="status green">
                        {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                    </span>
                } else {
                    return <span className="status red">
                        {capitalizeCamelCase(removeSpecialChars('Rejected'))}
                    </span>
                }
            }
            else if (d.approvalStatus === "Pending" && !d.hasOwnProperty('isApproved')) {
                return <ActionMenu id={i} handlers={_action} childMenuOpen={['moveTo']} childMenu={moveToList} handleChildMenuClick={(id) => handleChildMenuClick(id, i)} />; // Component to show all 4 buttons
            } else if (d.approvalStatus === "Approved" && d.isApproved === true) {
                return (
                    <span className="status green">
                        {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                    </span>
                ) // Component to just show status
            } else if (d.approvalStatus.includes("moveTo")) {
                delete _action.hold;
                delete _action.moveTo;
                return <ActionMenu id={i} handlers={_action} />; // Component to show only approve and reject buttons
            } else if ((d.approvalStatus === "Rejected" && d.isApproved === false)) {
                return (
                    <span className="status red">
                        {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                    </span>
                ) // Component to just show status
            } else if (d.approvalStatus.includes("Hold")) {
                delete _action.hold;
                return <ActionMenu id={i} handlers={_action} childMenuOpen={['moveTo']} childMenu={moveToList} handleChildMenuClick={(id) => handleChildMenuClick(id, i)} />
                // return (
                //     <span className="status yellow">
                //         {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                //     </span>
                // ) // Component to just show status
            }
        }

        row.push(ActionStatus());
        return row;
    });

    const onPageChange = (page_no) => {
        filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
        refetch(filterRef.current);
    };
    const onPageSizeChange = (e) => {
        const { value } = e.target;
        filterRef.current = { ...filterRef.current, pagesize: value };
        refetch(filterRef.current);
    };

    if (isFetched && userPermissions && Object.keys(userPermissions).length && userDetail && !(userDetail?.userRole == "bayOperator" || userPermissions?.manageApprovals?.value)) return <Navigate to="/not-authorized" replace />;
    // console.log('api response', approvalList);
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className="cust-col-5">
                    <h1 className="title">Approval Management</h1>
                </div>
            </div>
            <hr className="bgg" />
            {/* <div className="cust-row flex-algn-cent">
                <Genericfilter
                    filterFields={filterFields}
                    onFilterChange={(filter) => handleFilterChange(filter)}
                />
            </div> */}
            {selectedTickets.length > 0 && <div style={{ display: 'flex', justifyContent: 'start', gap: "20px", marginBottom: "20px" }}>
                <Button
                    onClick={() => handleMultiAction('approve')}
                    text={'Approve'}
                    style={{ ...buttonClasses.lynkitGreenFill, width: "100px" }}
                />
                <Button
                    onClick={() => handleMultiAction('reject')}
                    text={'Reject'}
                    style={{ ...buttonClasses.lynkitRedFill, width: "100px" }}
                />
                <Button
                    onClick={() => handleMultiAction('hold')}
                    text={'Hold'}
                    style={{ ...buttonClasses.lynkitYellowFill, width: "100px" }}
                />
            </div>}
            <div className="main_content">
                <GenericTable
                    header={header}
                    rows={rows}
                    pageCount={count}
                    pageNo={filterRef.current.page_no}
                    limit={filterRef.current.pagesize}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    isLoading={isFetching}
                />
            </div>
            {approvalTemplateId && approvalTemplateId?.length > 0 && approvalPayload && approvalPayload?.length > 0 && <ApprovalForm open={approvalTemplateId} onClose={onModalClose} templateId={approvalTemplateId} payload={approvalPayload} approvalList={approvalList} approvalAction={approvalAction} />}

        </div>
    )
}


function CheckResponse(errorArr) {
    const res = { pass: 0, fail: 0 }
    errorArr.forEach((ele, index) => {
        if (!ele.error) {
            res['pass'] = res['pass'] + 1
        } else {
            res['fail'] = res['fail'] + 1
        }
    })

    return res
}

function ApprovalForm({ onClose, open, templateId, payload, approvalList, approvalAction }) {
    const [updateTicketTrigger] = useUpdateApprovalMutation();
    const [hideTemplate, setHideTemplate] = useState([])
    const handleFormSubmit = async (data, index) => {
        const Payload = { ...payload[index], formData: data.data }
        // console.log('submit on dynamic', Payload, index, payload, templateId);

        await updateTicketTrigger({ ...Payload }).unwrap()
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    toastMessage(true, `Trip is updated successfully`);
                    if (hideTemplate.length === (templateId.length-1)) {
                        handleClose()
                    } else {
                        setHideTemplate((prev) => [...prev, index])
                    }
                }
            })
            .catch((err) => {
                console.log('error updating approval', err);
            })

    };
    const handleClose = () => {
        onClose();
    };

    // if (isLoading) return <Loader size="3rem" height="100%" />;
    // if (isInValidCheckpoint) return <Navigate to={REDIRECTION_URL} replace={true} />;

    return (
        <Dialog size="sm" open={true} handleClose={onClose} title={approvalAction} noBtn>
            {templateId.map((el, index) => <div key={`${el}-${index}`}>
                {approvalList ? <span style={{fontSize : '15px', fontWeight : 'bold'}}> Trip Id : {approvalList.find((e) => e._id ===  payload[index].reference_id[0]).trip_counter || ""}</span> : ""}
                {!hideTemplate.includes(index) && <DynamicFormRender
                    templateId={el}
                    checkpointId={""}
                    onClose={handleClose}
                    onSubmit={(data) => handleFormSubmit(data, index)}
                // isSubmitDisabled={isSubmitting}
                />}
            </div>)
            }

        </Dialog>
    );
}
