import { createContext, useContext, useState } from "react";

const TourContext = createContext();
export function TourProvider(props) {
	const [receivedGuidedTour, setReceivedGuidedTour] = useState(true);
	const value = [receivedGuidedTour, setReceivedGuidedTour];
	return <TourContext.Provider value={value} {...props} />;
}
export function useTour() {
	const context = useContext(TourContext);
	if (!context) {
		throw new Error("useTour must be within a TourProvider");
	}
	return context;
}
