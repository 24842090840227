import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dialog, Dropdown, Loader, toastMessage, Checkbox, Switcher } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import { capitalizeCamelCase, capitalizeSentence, findDeepValue, flattenObj, formatDate, parseUnderscore, removeSpecialChars } from "../../services/functions";
import { useGetOrgSettingQuery } from "../../api/vmsSlice";
import { useUserDetail } from "../../services/hooks";
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import LynkIdlogo from "../../LynkIdlogo.png";

const labelData = {
    "border": "5px solid #8a8686",
    "allFields": [
        {
            "type": "logo",
            "fieldName": "Show Logo",
            "x": 6,
            "y": 36,
            "width": 120,
            "height": 40,
            "padding": 5,
            "style": {
                "width": "100%",
                "height": "40px"
            },
            "disabled": false
        },
        {
            "type": "name",
            "x": 61,
            "fieldName": "Show Company",
            "y": 35,
            "width": 268,
            "height": 40,
            "padding": 5,
            "disabled": false,
            "style": {
                "fontWeight": "800",
                "lineHeight": "20px",
                "fontSize": "18px",
                "color": "#000000"
            }
        },
        {
            "type": "itemDetail",
            "x": 0,
            "fieldName": "Show Detail",
            "y": 0,
            "width": 300,
            "padding": 5,
            "multiline": false,
            "subItems": [],
            "disabled": false
        },
        {
            "type": "qr",
            "x": 54,
            "fieldName": "Label Type",
            "y": 30,
            "width": 60,
            "height": 60,
            "disabled": false,
            "dynamicDetail": '1',
            "qrType": "qrCode"
        }
    ],
    "fieldsConfig": {
        "top": {
            "style": {
                "display": "flex",
                "justifyContent": "space-between",
                "height": '100px'
                // "border": "1px solid black"
            }
        },
        "content": {
            "style": {
                "display": "flex",
                "justifyContent": "space-between"
            }
        }
    },
    "width": "100%",
    // "height": "500px",
    "height": "auto",
    "style": {
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "start",
        "border": "2px solid grey"
    },
    "label": "Inventory 1",
    "showBorder": false,
}

export default function PrintLabel({ open, onClose, detailData, detailTableHeaders }) {
    const componentRef = useRef();
    const [labelDetail, setLabelDetail] = useState(labelData);
    const [previewForPrint, setPreviewForPrint] = useState(false);
    const [readyForPrint, setReadyForPrint] = useState(false);
    const [selection, setSelection] = useState({
        labelType: 'qrCode',
        companyName: true,
        logo: true,
        defaultFilter: true,
        // resetFilter: false,
    });
    const { userDetail } = useUserDetail();
    const { data, isFetching, isLoading } = useGetOrgSettingQuery({}, {refetchOnMountOrArgChange: true,});
    let { data: orgSetting = {} } = data || { data: {} };

    const [filterData, setFilterData] = useState(processedData(detailData, detailTableHeaders, orgSetting?.pdfSettings?.default_fields || []));
    // console.table(JSON.stringify(detailData))
    // console.log(detailTableHeaders);

    // console.log('filter', detailData?.trip_counter)
    const handlefilter = (name, value) => {
        if (name === 'companyName') {
            setSelection((prevState) => ({ ...prevState, companyName: value }));
            setLabelDetail((prevElements) => {
                let newElements = { ...prevElements };
                newElements = {
                    ...newElements,
                    allFields: newElements?.allFields.map((ele, index) => {
                        if (ele.type === 'name') {
                            newElements.allFields[index].disabled = !value
                        }
                        return ele;
                    })
                };
                return newElements;
            });
        }
        if (name === 'logo') {
            setSelection((prevState) => ({ ...prevState, logo: value }));
            setLabelDetail((prevElements) => {
                let newElements = { ...prevElements };
                newElements = {
                    ...newElements,
                    allFields: newElements?.allFields.map((ele, index) => {
                        if (ele.type === 'logo') {
                            newElements.allFields[index].disabled = !value
                        }
                        return ele;
                    })
                };
                return newElements;
            });
        }
        if (name === 'barCode' || name === 'qrCode') {
            setSelection((prevState) => ({ ...prevState, labelType: name }));
            setLabelDetail((prevElements) => {
                let newElements = { ...prevElements };
                newElements = {
                    ...newElements,
                    allFields: newElements?.allFields.map((ele, index) => {
                        if (ele.type === 'qr') {
                            newElements.allFields[index].qrType = name
                        }
                        return ele;
                    })
                };
                return newElements;
            });
        }
        if(name === 'defaultFilter') {
            setSelection((prevState) => ({ ...prevState, defaultFilter: value }));
        }
        // console.log(name, value)
    }

    const handleEdit = () => {
        // console.log('editt')
        // console.log(labelDetail);
        // console.log(filterData?.allFields)
        setReadyForPrint(true)
        setLabelDetail((prevElements) => {
            let newElements = { ...prevElements };
            newElements = {
                ...newElements,
                allFields: newElements?.allFields.map((ele, index) => {
                    if (ele.type === 'itemDetail') {
                        newElements.allFields[index].subItems = filterData?.allFields
                    }
                    return ele;
                })
            };
            return newElements;
        });
    };

    const handlePreview = () => {
        // console.log('preview')
        setPreviewForPrint(true);
        // setReadyForPrint(true);
    };

    const handleCancel = () => {
        if (readyForPrint) {
            setReadyForPrint(false);
            setLabelDetail(labelData);
        }
        if (previewForPrint) {
            setPreviewForPrint(false);
            // setLabelDetail(labelData);
        }
        if (!readyForPrint && !previewForPrint) {
            // console.log('cancel', labelData)
            setLabelDetail(labelData);
            onClose()
        }

    }

    useEffect(() => {
        if (!isLoading) {
            if(!selection?.defaultFilter){
                // console.log('filter-calling======>')
                setFilterData(processedData(detailData, detailTableHeaders, []))
            }
            setFilterData(processedData(detailData, detailTableHeaders, orgSetting?.pdfSettings?.default_fields || [], selection.defaultFilter))
        }
    }, [isLoading, selection?.defaultFilter])

    useEffect(() => {
        if (detailData?.trip_counter && userDetail) {
            // console.log('filter-calling======>', detailData?.trip_counter, userDetail?.orgName);
            setLabelDetail((prevElements) => {
                let newElements = { ...prevElements };
                newElements = {
                    ...newElements,
                    companyName : userDetail?.orgName,
                    allFields: newElements?.allFields.map((ele, index) => {
                        if (ele.type === 'qr') {
                            newElements.allFields[index].dynamicDetail = detailData?.trip_counter || 1
                        }
                        return ele;
                    })
                };
                return newElements;
            });
        }
    }, [detailData?.trip_counter, userDetail, labelDetail])
    return (
        <Dialog
            size="md"
            open={true}
            handleSubmit={() => !readyForPrint ? handleEdit() : handlePreview()}
            handleClose={() => {
                setLabelDetail(labelData);
                onClose()
            }}
            handleCancel={() => handleCancel()}
            title={"Generate and Print Labels"}
            noBtn={previewForPrint}
            SubmitText={!readyForPrint ? 'Edit for Print' : 'Preview before Print'}
        >
            {!readyForPrint ? <div className="print_selection_container">
                <div className="level_1">
                    <Checkbox
                        id={'defaultFilter'}
                        name={'defaultFilter'}
                        checked={selection?.defaultFilter}
                        label="Reset Filter"
                        onChange={(e) => {
                            handlefilter(e.target.name, e.target.checked);
                        }}
                    />
                    <Checkbox
                        id={'barcode'}
                        name={'barCode'}
                        checked={selection?.labelType === 'barCode' ? true : false}
                        label="Bar Code"
                        onChange={(e) => {
                            handlefilter(e.target.name, e.target.checked);
                        }} />
                    <Checkbox
                        id={'qrcode'}
                        name={'qrCode'}
                        checked={selection?.labelType === 'qrCode' ? true : false}
                        label="QR Code"
                        onChange={(e) => {
                            handlefilter(e.target.name, e.target.checked);
                        }}
                    />
                    <Checkbox
                        id={'logo'}
                        name={'logo'}
                        checked={selection?.logo}
                        label="Show Logo"
                        onChange={(e) => {
                            handlefilter(e.target.name, e.target.checked);
                        }}
                    />
                    <Checkbox
                        id={'companyName'}
                        name={'companyName'}
                        checked={selection?.companyName}
                        label="Show Company Name"
                        onChange={(e) => {
                            handlefilter(e.target.name, e.target.checked);
                        }}
                    />

                </div>
                {filterData?.allFields.map((ele) => (
                    <div key={ele.displayName}>
                        <div className="level_heading">
                            <Checkbox
                                id={ele.displayName}
                                name={ele.displayName}
                                checked={ele?.fields?.every((ele) => ele.isChecked) ? true : false}
                                label={ele.displayName}
                                onChange={(e) => {
                                    // console.log('ele', ele)
                                    setFilterData((prevState) => ({
                                        ...prevState,
                                        allFields: prevState.allFields.map((item) => {
                                            if (item.displayName === ele.displayName) {
                                                return {
                                                    ...item,
                                                    fields: item.fields.map((fieldItem) => {
                                                        return { ...fieldItem, isChecked: e.target.checked };
                                                    }),
                                                };
                                            }
                                            return item;
                                        }),
                                    }));
                                }}
                            />
                        </div>
                        <div className="level_1">
                            {ele?.fields.map((el) => (
                                <div style={{ width: '260px' }} key={el.label}>
                                    <Checkbox
                                        id={el.label}
                                        name={el.label}
                                        checked={el.isChecked}
                                        label={el.label}
                                        onChange={(e) => {
                                            setFilterData((prevState) => ({
                                                ...prevState,
                                                allFields: prevState.allFields.map((item) => {
                                                    if (item.displayName === ele.displayName) {
                                                        return {
                                                            ...item,
                                                            fields: item.fields.map((fieldItem) => {
                                                                if (fieldItem.label === e.target.name) {
                                                                    return { ...fieldItem, isChecked: e.target.checked };
                                                                }
                                                                return fieldItem;
                                                            }),
                                                        };
                                                    }
                                                    return item;
                                                }),
                                            }));
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div> : null}
            {readyForPrint && <div ref={componentRef} id="toPrint" style={{border: "1px solid transparent",  padding: '20px' }}>

                <Resizable
                    style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", ...(labelDetail.style || {}) }}
                    size={{
                        width: labelDetail.width || 500,
                        height: labelDetail.height || 'auto',
                    }}
                    onResizeStop={(e, direction, ref, d) => {
                        // console.log(d);
                        setLabelDetail((prevElements) => {
                            let newElements = { ...prevElements };
                            newElements = {
                                ...newElements,
                                width: newElements.width + d.width,
                                height: newElements.height + d.height,
                            };
                            return newElements;
                        });
                    }}
                >
                    <div className="top" style={labelDetail?.fieldsConfig?.top?.style || {}}>
                        {labelDetail?.allFields?.map((element, index) => {
                            if (!element.disabled && (['qr', 'logo', 'name'].includes(element.type))) {
                                return <RenderElement element={element} index={index} labelDetail={labelDetail} setLabelDetail={setLabelDetail} disabledDrag={previewForPrint} />;
                            }
                        })}
                        <div></div>
                    </div>
                    <hr className="bgg" />
                    <div className="content" style={labelDetail?.fieldsConfig?.content?.style || {}}>
                        {labelDetail?.allFields?.map((element, index) => {
                            if (!element.disabled && (element.type === 'itemDetail')) {
                                return <RenderElement element={element} index={index} labelDetail={labelDetail} setLabelDetail={setLabelDetail} disabledDrag={previewForPrint} />;
                            }
                        })}
                    </div>
                    {/* <div className="footer">
                    {labelDetail?.allFields?.map((element, index) => {
                        if (!element.disabled && element.type === 'signature') {
                            return renderElement(element, index, labelDetail, setLabelDetail);
                        }
                    })}
                </div> */}

                </Resizable>
            </div>}
            {previewForPrint && <div className="print-btn">
                <Button
                    style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fitContent" }}
                    onClick={() => setPreviewForPrint(false)}
                >
                    Back
                </Button>
                <ReactToPrint
                    documentTitle="Label"
                    onAfterPrint={() => setReadyForPrint(true)}
                    // bodyClass="adjustContent"
                    removeAfterPrint
                    trigger={() => <Button
                        style={{ ...buttonClasses.lynkitOrangeFill, width: "fitContent" }}
                    >
                        Print
                    </Button>}
                    content={() => componentRef.current}
                />
            </div>}
        </Dialog>
    )
}

const RenderElement = ({ element, index, labelDetail, setLabelDetail, disabledDrag = false }) => {

    const [enableParentDrag, setEnableParentDrag] = useState('');

    const handleDrag = (x, y) => {
        setLabelDetail((prevElements) => {
            const newElements = { ...prevElements };
            newElements["allFields"][index] = {
                ...element,
                x,
                y,
            };
            return newElements;
        });
    };

    const handleResize = (d) => {
        // console.log(d);
        setLabelDetail((prevElements) => {
            const newElements = { ...prevElements };
            newElements["allFields"][index] = {
                ...element,
                width: element.width + d.width,
                height: element.height + d.height,
            };
            return newElements;
        });
    };

    const renderContent = () => {
        switch (element.type) {
            case 'logo':
                return (
                    <>
                        {!disabledDrag && <div className="drag-container" onMouseEnter={() => setEnableParentDrag(element.type)} onMouseLeave={() => setEnableParentDrag('')}>{pepicon()}</div>}
                        <div className="logo">
                            <img
                                src={localStorage.getItem("logo") || LynkIdlogo}
                                width={element?.style?.width || "inherit"}
                                height={element?.style?.height || "inherit"}
                                alt="Logo"
                            />
                        </div>
                    </>
                );
            case 'name':
                return (
                    <>
                        {!disabledDrag && <div className="drag-container" onMouseEnter={() => setEnableParentDrag(element.type)} onMouseLeave={() => setEnableParentDrag('')}>{pepicon()}</div>}
                        <div className="name" style={element.style || {
                            fontWeight: 'bold',
                            fontSize: "18px",
                            color: "#000000"
                        }}>
                            {labelDetail?.companyName}
                        </div>
                    </>

                );
            case 'itemDetail':
                return (
                    <Resizable
                        style={{ border: `${!disabledDrag ? 'none' : 'none'}`, padding: element.padding || "" }}
                        size={{ width: '100%', height: element.height }}
                        onResizeStop={(e, direction, ref, d) => handleResize(d)}
                    >

                        {element.subItems.map((parentElement, parentIndex) => (
                            (parentElement?.fields?.some((ele) => ele.isChecked)) && <Draggable
                                key={parentIndex}
                                defaultPosition={{ x: element.x, y: element.y }}
                                onDrag={(e, { x, y }) => {
                                    e.stopPropagation();
                                    handleDrag(x, y);
                                }}
                                disabled={disabledDrag ? true : enableParentDrag === parentIndex ? false : true}
                            >
                                <Resizable
                                    style={{ border: `${!disabledDrag ? "2px dotted rgb(202, 207, 191)" : '2px dotted transparent'}`, marginBottom: '15px', borderRadius: '10px', position: "relative", padding: `5px 5px 5px 20px` || "" }}
                                    size={{ width: '100%', height: element.height }}
                                    onResizeStop={(e, direction, ref, d) => handleResize(d)}
                                >
                                    {!disabledDrag && <div className="drag-container" onMouseEnter={() => setEnableParentDrag(parentIndex)} onMouseLeave={() => setEnableParentDrag('')}>{pepicon()}</div>}
                                    <h4 style={{ fontSize: '18px' }}>{parentElement.displayName}</h4>
                                    <hr />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                        {parentElement?.fields?.map((detailElement, detailIndex) => (
                                            detailElement?.isChecked && <Draggable
                                                key={detailIndex}
                                                defaultPosition={{ x: detailElement.x, y: detailElement.y }}
                                                onDrag={(e, { x, y }) => {
                                                    e.stopPropagation(); // Prevent parent drag
                                                    setLabelDetail((prevElements) => {
                                                        const newElements = { ...prevElements };
                                                        const fieldElement = newElements["allFields"][index];
                                                        fieldElement.subItems = fieldElement.subItems.map((subItem, subIndex) => {
                                                            subItem.fields = subItem.fields.map((field, fieldIndex) => {
                                                                if (fieldIndex === detailIndex) {
                                                                    return {
                                                                        ...field,
                                                                        x,
                                                                        y,
                                                                    };
                                                                }
                                                                return field;
                                                            });
                                                            return subItem;
                                                        });
                                                        return newElements;
                                                    });
                                                }}
                                                disabled={disabledDrag ? true : false}
                                            >
                                                <div style={element.style || {
                                                    // fontWeight: 'bold',
                                                    // fontSize: "18px",
                                                    color: "#000000",
                                                    border: `${!disabledDrag ? "2px dotted rgb(202, 207, 191)" : '2px dotted transparent'}`,
                                                    cursor: 'pointer'
                                                }}>
                                                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{parseUnderscore(capitalizeCamelCase(detailElement.label))} : </span>
                                                    <span style={{ fontSize: '10px' }}>{detailElement.value || "-"}</span>
                                                </div>
                                            </Draggable>
                                        ))}
                                    </div>
                                </Resizable>
                            </Draggable>
                        ))}

                    </Resizable>
                );
            case 'qr':
                return (
                    <>
                        {!disabledDrag && <div className="drag-container" onMouseEnter={() => setEnableParentDrag(element.type)} onMouseLeave={() => setEnableParentDrag('')}>{pepicon()}</div>}
                        <div className="qr">
                            {element.qrType === "qrCode"
                                ? <QRCode
                                    value={getEncodingData(element.dynamicDetail)}
                                    style={{
                                        width: element.width,
                                        height: element.height,
                                    }}
                                />
                                : <Barcode
                                    value={getEncodingData(element.dynamicDetail)}
                                    width={1}
                                    height={element.height}
                                    displayValue={false}
                                />}
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        element.type === 'itemDetail' ?
            renderContent()
            :
            <Draggable
                key={index}
                defaultPosition={{ x: element.x, y: element.y }}
                onDrag={(e, { x, y }) => {
                    e.stopPropagation();
                    handleDrag(x, y);
                }}
                disabled={enableParentDrag === element.type ? false : true}
            >
                <Resizable
                    style={{ border: `${!disabledDrag ? "2px dotted rgb(202, 207, 191)" : '2px dotted transparent'}`, borderRadius: '10px', position: "relative", padding: '0px 5px 0px 20px' || "", display: 'flex', alignItems: 'center' }}
                    size={{ width: element.width, height: element.height }}
                    onResizeStop={(e, direction, ref, d) => handleResize(d)}
                >
                    {renderContent()}
                </Resizable>

            </Draggable>
    );
};

const processedData = (data, tableHeader, defaultFields, filterState) => {
    const newStaticDetail = data?.statusHistory?.map(({ displayName, inTime, outTime, checkpointId, checkpointType }, i) => {
        let type;
        let res = data?.data?.map((_, j) => {
            let result = {};
            if (_.checkpointId === checkpointId) {
                flattenObj(result, _.formData);
                type = _.type;
                delete result._id;
            }
            result['inTime'] = inTime
            result['outTime'] = outTime
            return result;
        });

        const newRes = {
            displayName: displayName,
            checkpointId: checkpointId,
            fields: res?.length > 0 ? Object.keys(res[i])?.map((_) => ({
                isChecked: false,
                label: _,
                value: res[i][_],
                "x": 0,
                "y": 0,
                "width": 300,
                "height": 20,
                "padding": 5
            })) : [
                {
                    "isChecked": false,
                    "label": "inTime",
                    "value": inTime,
                    "x": 0,
                    "y": 0,
                    "width": 300,
                    "height": 20,
                    "padding": 5
                },
                {
                    "isChecked": false,
                    "label": "outTime",
                    "value": outTime,
                    "x": 0,
                    "y": 0,
                    "width": 300,
                    "height": 20,
                    "padding": 5
                }
            ],
        }

        return newRes;
    });
    const details = tableHeader?.map((_) => ({
        "x": 0,
        "y": 0,
        "width": 300,
        "height": 20,
        "padding": 5, isChecked: false,
        label: _.label,
        value: _.type == "date" ? formatDate(findDeepValue(data, _.name.split("."))) : findDeepValue(data, _.name.split("."))
    }));


    const allField = { allFields: [{ displayName: 'Basic Details', checkpointId: 'BasicDetails', fields: details }, ...newStaticDetail], }

    return filterProcessedData(defaultFields, allField.allFields, filterState)
    // return allField;
}


const filterProcessedData = (defaultData, filteredData, filterState) => {

    // console.log('defaultData====>', defaultData);
    // console.log('filteredData====>', filteredData);
    if( !filterState){
        return { 'allFields': filteredData };
    }
    if ((defaultData?.length === 0 || defaultData === undefined || defaultData === null)) {
        return { 'allFields': filteredData };
    }
    const result = filteredData.map((ele, index) => {
        const defaultele = defaultData.find((_) => _.checkpointId === ele.checkpointId);
        // console.log('defaultele====>', defaultele);
        const field = ele?.fields.map((_, index) => {
            const defaultField = defaultele?.fields.find((__) => __.label === _.label);
            if (defaultField) {
                _.isChecked = defaultField.isChecked;
            }
            return _;
        })
        return {
            ...ele,
            displayName: defaultele?.displayName || ele.displayName,
            fields: field
        }
    })
    return { 'allFields': result };
}

const getEncodingData = (data) => {
    // console.log('dynamicDetail====>', data);
    return data;
}


const pepicon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20">
            <g fill="currentColor">
                <path d="M11 6.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0z" opacity="0.2" />
                <path d="M9 15.25a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0m0-5a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0m0-5a1.249 1.249 0 1 1 2.5 0a1.25 1.25 0 1 1-2.5 0" />
            </g>
        </svg>
    )
}

