export const speechLanguageOptions = [
	{ value: "af-ZA", label: "Afrikaans (South Africa)" },
	{ value: "am-ET", label: "Amharic (Ethiopia)" },
	{ value: "ar-AE", label: "Arabic (United Arab Emirates)" },
	{ value: "ar-BH", label: "Arabic (Bahrain)" },
	{ value: "ar-DZ", label: "Arabic (Algeria)" },
	{ value: "ar-EG", label: "Arabic (Egypt)" },
	{ value: "ar-IQ", label: "Arabic (Iraq)" },
	{ value: "ar-JO", label: "Arabic (Jordan)" },
	{ value: "ar-KW", label: "Arabic (Kuwait)" },
	{ value: "ar-LB", label: "Arabic (Lebanon)" },
	{ value: "ar-LY", label: "Arabic (Libya)" },
	{ value: "ar-MA", label: "Arabic (Morocco)" },
	{ value: "ar-OM", label: "Arabic (Oman)" },
	{ value: "ar-QA", label: "Arabic (Qatar)" },
	{ value: "ar-SA", label: "Arabic (Saudi Arabia)" },
	{ value: "ar-SY", label: "Arabic (Syria)" },
	{ value: "ar-TN", label: "Arabic (Tunisia)" },
	{ value: "ar-YE", label: "Arabic (Yemen)" },
	{ value: "as-IN", label: "Assamese (India)" },
	{ value: "az-AZ", label: "Azerbaijani (Latin, Azerbaijan)" },
	{ value: "bg-BG", label: "Bulgarian (Bulgaria)" },
	{ value: "bn-BD", label: "Bangla (Bangladesh)" },
	{ value: "bn-IN", label: "Bengali (India)" },
	{ value: "bs-BA", label: "Bosnian (Bosnia and Herzegovina)" },
	{ value: "ca-ES", label: "Catalan" },
	{ value: "cs-CZ", label: "Czech (Czechia)" },
	{ value: "cy-GB", label: "Welsh (United Kingdom)" },
	{ value: "da-DK", label: "Danish (Denmark)" },
	{ value: "de-AT", label: "German (Austria)" },
	{ value: "de-CH", label: "German (Switzerland)" },
	{ value: "de-DE", label: "German (Germany)" },
	{ value: "el-GR", label: "Greek (Greece)" },
	{ value: "en-AU", label: "English (Australia)" },
	{ value: "en-CA", label: "English (Canada)" },
	{ value: "en-GB", label: "English (United Kingdom)" },
	{ value: "en-HK", label: "English (Hong Kong SAR)" },
	{ value: "en-IE", label: "English (Ireland)" },
	{ value: "en-IN", label: "English (India)" },
	{ value: "en-KE", label: "English (Kenya)" },
	{ value: "en-NG", label: "English (Nigeria)" },
	{ value: "en-NZ", label: "English (New Zealand)" },
	{ value: "en-PH", label: "English (Philippines)" },
	{ value: "en-SG", label: "English (Singapore)" },
	{ value: "en-TZ", label: "English (Tanzania)" },
	{ value: "en-US", label: "English (United States)" },
	{ value: "en-ZA", label: "English (South Africa)" },
	{ value: "es-AR", label: "Spanish (Argentina)" },
	{ value: "es-BO", label: "Spanish (Bolivia)" },
	{ value: "es-CL", label: "Spanish (Chile)" },
	{ value: "es-CO", label: "Spanish (Colombia)" },
	{ value: "es-CR", label: "Spanish (Costa Rica)" },
	{ value: "es-CU", label: "Spanish (Cuba)" },
	{ value: "es-DO", label: "Spanish (Dominican Republic)" },
	{ value: "es-EC", label: "Spanish (Ecuador)" },
	{ value: "es-ES", label: "Spanish (Spain)" },
	{ value: "es-GQ", label: "Spanish (Equatorial Guinea)" },
	{ value: "es-GT", label: "Spanish (Guatemala)" },
	{ value: "es-HN", label: "Spanish (Honduras)" },
	{ value: "es-MX", label: "Spanish (Mexico)" },
	{ value: "es-NI", label: "Spanish (Nicaragua)" },
	{ value: "es-PA", label: "Spanish (Panama)" },
	{ value: "es-PE", label: "Spanish (Peru)" },
	{ value: "es-PR", label: "Spanish (Puerto Rico)" },
	{ value: "es-PY", label: "Spanish (Paraguay)" },
	{ value: "es-SV", label: "Spanish (El Salvador)" },
	{ value: "es-US", label: "Spanish (United States)" },
	{ value: "es-UY", label: "Spanish (Uruguay)" },
	{ value: "es-VE", label: "Spanish (Venezuela)" },
	{ value: "et-EE", label: "Estonian (Estonia)" },
	{ value: "eu-ES", label: "Basque" },
	{ value: "fa-IR", label: "Persian (Iran)" },
	{ value: "fi-FI", label: "Finnish (Finland)" },
	{ value: "fil-PH", label: "Filipino (Philippines)" },
	{ value: "fr-BE", label: "French (Belgium)" },
	{ value: "fr-CA", label: "French (Canada)" },
	{ value: "fr-CH", label: "French (Switzerland)" },
	{ value: "fr-FR", label: "French (France)" },
	{ value: "ga-IE", label: "Irish (Ireland)" },
	{ value: "gl-ES", label: "Galician" },
	{ value: "gu-IN", label: "Gujarati (India)" },
	{ value: "he-IL", label: "Hebrew (Israel)" },
	{ value: "hi-IN", label: "Hindi (India)" },
	{ value: "hr-HR", label: "Croatian (Croatia)" },
	{ value: "hu-HU", label: "Hungarian (Hungary)" },
	{ value: "hy-AM", label: "Armenian (Armenia)" },
	{ value: "id-ID", label: "Indonesian (Indonesia)" },
	{ value: "is-IS", label: "Icelandic (Iceland)" },
	{ value: "it-IT", label: "Italian (Italy)" },
	{ value: "CANS-CA", label: "Inuktitut (Syllabics, Canada)" },
	{ value: "LATN-CA", label: "Inuktitut (Latin, Canada)" },
	{ value: "ja-JP", label: "Japanese (Japan)" },
	{ value: "jv-ID", label: "Javanese (Latin, Indonesia)" },
	{ value: "ka-GE", label: "Georgian (Georgia)" },
	{ value: "kk-KZ", label: "Kazakh (Kazakhstan)" },
	{ value: "km-KH", label: "Khmer (Cambodia)" },
	{ value: "kn-IN", label: "Kannada (India)" },
	{ value: "ko-KR", label: "Korean (Korea)" },
	{ value: "lo-LA", label: "Lao (Laos)" },
	{ value: "lt-LT", label: "Lithuanian (Lithuania)" },
	{ value: "lv-LV", label: "Latvian (Latvia)" },
	{ value: "mk-MK", label: "Macedonian (North Macedonia)" },
	{ value: "ml-IN", label: "Malayalam (India)" },
	{ value: "mn-MN", label: "Mongolian (Mongolia)" },
	{ value: "mr-IN", label: "Marathi (India)" },
	{ value: "ms-MY", label: "Malay (Malaysia)" },
	{ value: "mt-MT", label: "Maltese (Malta)" },
	{ value: "my-MM", label: "Burmese (Myanmar)" },
	{ value: "nb-NO", label: "Norwegian Bokmål (Norway)" },
	{ value: "ne-NP", label: "Nepali (Nepal)" },
	{ value: "nl-BE", label: "Dutch (Belgium)" },
	{ value: "nl-NL", label: "Dutch (Netherlands)" },
	{ value: "or-IN", label: "Oriya (India)" },
	{ value: "pa-IN", label: "Punjabi (India)" },
	{ value: "pl-PL", label: "Polish (Poland)" },
	{ value: "ps-AF", label: "Pashto (Afghanistan)" },
	{ value: "pt-BR", label: "Portuguese (Brazil)" },
	{ value: "pt-PT", label: "Portuguese (Portugal)" },
	{ value: "ro-RO", label: "Romanian (Romania)" },
	{ value: "ru-RU", label: "Russian (Russia)" },
	{ value: "si-LK", label: "Sinhala (Sri Lanka)" },
	{ value: "sk-SK", label: "Slovak (Slovakia)" },
	{ value: "sl-SI", label: "Slovenian (Slovenia)" },
	{ value: "so-SO", label: "Somali (Somalia)" },
	{ value: "sq-AL", label: "Albanian (Albania)" },
	{ value: "LATN-RS", label: "Serbian (Latin, Serbia)" },
	{ value: "sr-RS", label: "Serbian (Cyrillic, Serbia)" },
	{ value: "su-ID", label: "Sundanese (Indonesia)" },
	{ value: "sv-SE", label: "Swedish (Sweden)" },
	{ value: "sw-KE", label: "Kiswahili (Kenya)" },
	{ value: "sw-TZ", label: "Kiswahili (Tanzania)" },
	{ value: "ta-IN", label: "Tamil (India)" },
	{ value: "ta-LK", label: "Tamil (Sri Lanka)" },
	{ value: "ta-MY", label: "Tamil (Malaysia)" },
	{ value: "ta-SG", label: "Tamil (Singapore)" },
	{ value: "te-IN", label: "Telugu (India)" },
	{ value: "th-TH", label: "Thai (Thailand)" },
	{ value: "tr-TR", label: "Turkish (Türkiye)" },
	{ value: "uk-UA", label: "Ukrainian (Ukraine)" },
	{ value: "ur-IN", label: "Urdu (India)" },
	{ value: "ur-PK", label: "Urdu (Pakistan)" },
	{ value: "uz-UZ", label: "Uzbek (Latin, Uzbekistan)" },
	{ value: "vi-VN", label: "Vietnamese (Vietnam)" },
	{ value: "wuu-CN", label: "Chinese (Wu, Simplified)" },
	{ value: "yue-CN", label: "Chinese (Cantonese, Simplified)" },
	{ value: "zh-CN", label: "Chinese (Mandarin, Simplified)" },
	{ value: "CN-GUANGXI", label: "Chinese (Guangxi Accent Mandarin, Simplified)" },
	{ value: "CN-henan", label: "Chinese (Zhongyuan Mandarin Henan, Simplified)" },
	{ value: "CN-liaoning", label: "Chinese (Northeastern Mandarin, Simplified)" },
	{ value: "CN-shaanxi", label: "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)" },
	{ value: "CN-shandong", label: "Chinese (Jilu Mandarin, Simplified)" },
	{ value: "CN-sichuan", label: "Chinese (Southwestern Mandarin, Simplified)" },
	{ value: "zh-HK", label: "Chinese (Cantonese, Traditional)" },
	{ value: "zh-TW", label: "Chinese (Taiwanese Mandarin, Traditional)" },
	{ value: "zu-ZA", label: "isiZulu (South Africa)" },
];




export const langToSpeakerMap = {
	"af-ZA": [
		{ label: "Adri", value: "af-ZA-AdriNeural" },
		{ label: "Willem", value: "af-ZA-WillemNeural" },
	],
	"am-ET": [
		{ label: "Mekdes", value: "am-ET-MekdesNeural" },
		{ label: "Ameha", value: "am-ET-AmehaNeural" },
	],
	"ar-AE": [
		{ label: "Fatima", value: "ar-AE-FatimaNeural" },
		{ label: "Hamdan", value: "ar-AE-HamdanNeural" },
	],
	"ar-BH": [
		{ label: "Laila", value: "ar-BH-LailaNeural" },
		{ label: "Ali", value: "ar-BH-AliNeural" },
	],
	"ar-DZ": [
		{ label: "Amina", value: "ar-DZ-AminaNeural" },
		{ label: "Ismael", value: "ar-DZ-IsmaelNeural" },
	],
	"ar-EG": [
		{ label: "Salma", value: "ar-EG-SalmaNeural" },
		{ label: "Shakir", value: "ar-EG-ShakirNeural" },
	],
	"ar-IQ": [
		{ label: "Rana", value: "ar-IQ-RanaNeural" },
		{ label: "Bassel", value: "ar-IQ-BasselNeural" },
	],
	"ar-JO": [
		{ label: "Sana", value: "ar-JO-SanaNeural" },
		{ label: "Taim", value: "ar-JO-TaimNeural" },
	],
	"ar-KW": [
		{ label: "Noura", value: "ar-KW-NouraNeural" },
		{ label: "Fahed", value: "ar-KW-FahedNeural" },
	],
	"ar-LB": [
		{ label: "Layla", value: "ar-LB-LaylaNeural" },
		{ label: "Rami", value: "ar-LB-RamiNeural" },
	],
	"ar-LY": [
		{ label: "Iman", value: "ar-LY-ImanNeural" },
		{ label: "Omar", value: "ar-LY-OmarNeural" },
	],
	"ar-MA": [
		{ label: "Mouna", value: "ar-MA-MounaNeural" },
		{ label: "Jamal", value: "ar-MA-JamalNeural" },
	],
	"ar-OM": [
		{ label: "Aysha", value: "ar-OM-AyshaNeural" },
		{ label: "Abdullah", value: "ar-OM-AbdullahNeural" },
	],
	"ar-QA": [
		{ label: "Amal", value: "ar-QA-AmalNeural" },
		{ label: "Moaz", value: "ar-QA-MoazNeural" },
	],
	"ar-SA": [
		{ label: "Zariyah", value: "ar-SA-ZariyahNeural" },
		{ label: "Hamed", value: "ar-SA-HamedNeural" },
	],
	"ar-SY": [
		{ label: "Amany", value: "ar-SY-AmanyNeural" },
		{ label: "Laith", value: "ar-SY-LaithNeural" },
	],
	"ar-TN": [
		{ label: "Reem", value: "ar-TN-ReemNeural" },
		{ label: "Hedi", value: "ar-TN-HediNeural" },
	],
	"ar-YE": [
		{ label: "Maryam", value: "ar-YE-MaryamNeural" },
		{ label: "Saleh", value: "ar-YE-SalehNeural" },
	],
	"as-IN": [
		{ label: "Priyom", value: "as-IN-PriyomNeural" },
		{ label: "Yashica", value: "as-IN-YashicaNeural" },
	],
	"az-AZ": [
		{ label: "Banu", value: "az-AZ-BanuNeural" },
		{ label: "Babek", value: "az-AZ-BabekNeural" },
	],
	"bg-BG": [
		{ label: "Kalina", value: "bg-BG-KalinaNeural" },
		{ label: "Borislav", value: "bg-BG-BorislavNeural" },
	],
	"bn-BD": [
		{ label: "Nabanita", value: "bn-BD-NabanitaNeural" },
		{ label: "Pradeep", value: "bn-BD-PradeepNeural" },
	],
	"bn-IN": [
		{ label: "Tanishaa", value: "bn-IN-TanishaaNeural" },
		{ label: "Bashkar", value: "bn-IN-BashkarNeural" },
	],
	"bs-BA": [
		{ label: "Vesna", value: "bs-BA-VesnaNeural" },
		{ label: "Goran", value: "bs-BA-GoranNeural" },
	],
	"ca-ES": [
		{ label: "Joana", value: "ca-ES-JoanaNeural" },
		{ label: "Enric", value: "ca-ES-EnricNeural" },
		{ label: "Alba", value: "ca-ES-AlbaNeural" },
	],
	"cs-CZ": [
		{ label: "Vlasta", value: "cs-CZ-VlastaNeural" },
		{ label: "Antonin", value: "cs-CZ-AntoninNeural" },
	],
	"cy-GB": [
		{ label: "Nia", value: "cy-GB-NiaNeural" },
		{ label: "Aled", value: "cy-GB-AledNeural" },
	],
	"da-DK": [
		{ label: "Christel", value: "da-DK-ChristelNeural" },
		{ label: "Jeppe", value: "da-DK-JeppeNeural" },
	],
	"de-AT": [
		{ label: "Ingrid", value: "de-AT-IngridNeural" },
		{ label: "Jonas", value: "de-AT-JonasNeural" },
	],
	"de-CH": [
		{ label: "Leni", value: "de-CH-LeniNeural" },
		{ label: "Jan", value: "de-CH-JanNeural" },
	],
	"de-DE": [
		{ label: "Katja", value: "de-DE-KatjaNeural" },
		{ label: "Conrad", value: "de-DE-ConradNeural" },
		{ label: "Amala", value: "de-DE-AmalaNeural" },
		{ label: "Bernd", value: "de-DE-BerndNeural" },
		{ label: "Elke", value: "de-DE-ElkeNeural" },
		{ label: "FlorianMultilingual", value: "de-DE-FlorianMultilingualNeural" },
		{ label: "Gisela", value: "de-DE-GiselaNeural" },
		{ label: "Killian", value: "de-DE-KillianNeural" },
		{ label: "Klarissa", value: "de-DE-KlarissaNeural" },
		{ label: "Klaus", value: "de-DE-KlausNeural" },
		{ label: "Louisa", value: "de-DE-LouisaNeural" },
		{ label: "Maja", value: "de-DE-MajaNeural" },
		{ label: "Ralf", value: "de-DE-RalfNeural" },
		{ label: "SeraphinaMultilingual", value: "de-DE-SeraphinaMultilingualNeural" },
	],
	"el-GR": [
		{ label: "Athina", value: "el-GR-AthinaNeural" },
		{ label: "Nestoras", value: "el-GR-NestorasNeural" },
	],
	"en-AU": [
		{ label: "Natasha", value: "en-AU-NatashaNeural" },
		{ label: "William", value: "en-AU-WilliamNeural" },
		{ label: "Annette", value: "en-AU-AnnetteNeural" },
		{ label: "Carly", value: "en-AU-CarlyNeural" },
		{ label: "Darren", value: "en-AU-DarrenNeural" },
		{ label: "Duncan", value: "en-AU-DuncanNeural" },
		{ label: "Elsie", value: "en-AU-ElsieNeural" },
		{ label: "Freya", value: "en-AU-FreyaNeural" },
		{ label: "Joanne", value: "en-AU-JoanneNeural" },
		{ label: "Ken", value: "en-AU-KenNeural" },
		{ label: "Kim", value: "en-AU-KimNeural" },
		{ label: "Neil", value: "en-AU-NeilNeural" },
		{ label: "Tim", value: "en-AU-TimNeural" },
		{ label: "Tina", value: "en-AU-TinaNeural" },
	],
	"en-CA": [
		{ label: "Clara", value: "en-CA-ClaraNeural" },
		{ label: "Liam", value: "en-CA-LiamNeural" },
	],
	"en-GB": [
		{ label: "Sonia", value: "en-GB-SoniaNeural" },
		{ label: "Ryan", value: "en-GB-RyanNeural" },
		{ label: "Libby", value: "en-GB-LibbyNeural" },
		{ label: "Abbi", value: "en-GB-AbbiNeural" },
		{ label: "Bella", value: "en-GB-BellaNeural" },
		{ label: "Elliot", value: "en-GB-ElliotNeural" },
		{ label: "Ethan", value: "en-GB-EthanNeural" },
		{ label: "Hollie", value: "en-GB-HollieNeural" },
		{ label: "Maisie", value: "en-GB-MaisieNeural" },
		{ label: "Noah", value: "en-GB-NoahNeural" },
		{ label: "Oliver", value: "en-GB-OliverNeural" },
		{ label: "Olivia", value: "en-GB-OliviaNeural" },
		{ label: "Thomas", value: "en-GB-ThomasNeural" },
		{ label: "AdaMultilingual", value: "en-GB-AdaMultilingualNeural" },
		{ label: "OllieMultilingual", value: "en-GB-OllieMultilingualNeural" },
	],
	"en-HK": [
		{ label: "Yan", value: "en-HK-YanNeural" },
		{ label: "Sam", value: "en-HK-SamNeural" },
	],
	"en-IE": [
		{ label: "Emily", value: "en-IE-EmilyNeural" },
		{ label: "Connor", value: "en-IE-ConnorNeural" },
	],
	"en-IN": [
		{ label: "Neerja", value: "en-IN-NeerjaNeural" },
		{ label: "Prabhat", value: "en-IN-PrabhatNeural" },
		{ label: "Aarav", value: "en-IN-AaravNeural" },
		{ label: "Aashi", value: "en-IN-AashiNeural" },
		{ label: "Ananya", value: "en-IN-AnanyaNeural" },
		{ label: "Kavya", value: "en-IN-KavyaNeural" },
		{ label: "Kunal", value: "en-IN-KunalNeural" },
		{ label: "Rehaan", value: "en-IN-RehaanNeural" },
	],
	"en-KE": [
		{ label: "Asilia", value: "en-KE-AsiliaNeural" },
		{ label: "Chilemba", value: "en-KE-ChilembaNeural" },
	],
	"en-NG": [
		{ label: "Ezinne", value: "en-NG-EzinneNeural" },
		{ label: "Abeo", value: "en-NG-AbeoNeural" },
	],
	"en-NZ": [
		{ label: "Molly", value: "en-NZ-MollyNeural" },
		{ label: "Mitchell", value: "en-NZ-MitchellNeural" },
	],
	"en-PH": [
		{ label: "Rosa", value: "en-PH-RosaNeural" },
		{ label: "James", value: "en-PH-JamesNeural" },
	],
	"en-SG": [
		{ label: "Luna", value: "en-SG-LunaNeural" },
		{ label: "Wayne", value: "en-SG-WayneNeural" },
	],
	"en-TZ": [
		{ label: "Imani", value: "en-TZ-ImaniNeural" },
		{ label: "Elimu", value: "en-TZ-ElimuNeural" },
	],
	"en-US": [
		{ label: "AvaMultilingual", value: "en-US-AvaMultilingualNeural" },
		{ label: "AndrewMultilingual", value: "en-US-AndrewMultilingualNeural" },
		{ label: "EmmaMultilingual", value: "en-US-EmmaMultilingualNeural" },
		{ label: "BrianMultilingual", value: "en-US-BrianMultilingualNeural" },
		{ label: "Ava", value: "en-US-AvaNeural" },
		{ label: "Andrew", value: "en-US-AndrewNeural" },
		{ label: "Brian", value: "en-US-BrianNeural" },
		{ label: "Jenny", value: "en-US-JennyNeural" },
		{ label: "Guy", value: "en-US-GuyNeural" },
		{ label: "Aria", value: "en-US-AriaNeural" },
		{ label: "Davis", value: "en-US-DavisNeural" },
		{ label: "Jane", value: "en-US-JaneNeural" },
		{ label: "Jason", value: "en-US-JasonNeural" },
		{ label: "Sara", value: "en-US-SaraNeural" },
		{ label: "Tony", value: "en-US-TonyNeural" },
		{ label: "Nancy", value: "en-US-NancyNeural" },
		{ label: "Amber", value: "en-US-AmberNeural" },
		{ label: "Ana", value: "en-US-AnaNeural" },
		{ label: "Ashley", value: "en-US-AshleyNeural" },
		{ label: "Brandon", value: "en-US-BrandonNeural" },
		{ label: "Christopher", value: "en-US-ChristopherNeural" },
		{ label: "Cora", value: "en-US-CoraNeural" },
		{ label: "Elizabeth", value: "en-US-ElizabethNeural" },
		{ label: "Eric", value: "en-US-EricNeural" },
		{ label: "Jacob", value: "en-US-JacobNeural" },
		{ label: "Michelle", value: "en-US-MichelleNeural" },
		{ label: "Monica", value: "en-US-MonicaNeural" },
		{ label: "Roger", value: "en-US-RogerNeural" },
		{ label: "Steffan", value: "en-US-SteffanNeural" },
		{ label: "AIGenerate1", value: "en-US-AIGenerate1Neural" },
		{ label: "AIGenerate2", value: "en-US-AIGenerate2Neural" },
		{ label: "AlloyTurboMultilingual", value: "en-US-AlloyTurboMultilingualNeural" },
		{ label: "Blue", value: "en-US-BlueNeural" },
		{ label: "ChristopherMultilingual", value: "en-US-ChristopherMultilingualNeural" },
		{ label: "CoraMultilingual", value: "en-US-CoraMultilingualNeural" },
		{ label: "Kai", value: "en-US-KaiNeural" },
		{ label: "Luna", value: "en-US-LunaNeural" },
		{ label: "NovaTurboMultilingual", value: "en-US-NovaTurboMultilingualNeural" },
		{ label: "SteffanMultilingual", value: "en-US-SteffanMultilingualNeural" },
		{ label: "AlloyMultilingual", value: "en-US-AlloyMultilingualNeural" },
		{ label: "FableMultilingual", value: "en-US-FableMultilingualNeural" },
		{ label: "OnyxMultilingual", value: "en-US-OnyxMultilingualNeural" },
		{ label: "ShimmerMultilingual", value: "en-US-ShimmerMultilingualNeural" },
		{ label: "AlloyMultilingualHD", value: "en-US-AlloyMultilingualNeuralHD" },
		{ label: "FableMultilingualHD", value: "en-US-FableMultilingualNeuralHD" },
		{ label: "OnyxMultilingualNeHD", value: "en-US-OnyxMultilingualNeuralHD" },
		{ label: "ShimmerMultilingualHD", value: "en-US-ShimmerMultilingualNeuralHD" },
	],
	"en-ZA": [
		{ label: "Leah", value: "en-ZA-LeahNeural" },
		{ label: "Luke", value: "en-ZA-LukeNeural" },
	],
	"es-AR": [
		{ label: "Elena", value: "es-AR-ElenaNeural" },
		{ label: "Tomas", value: "es-AR-TomasNeural" },
	],
	"es-BO": [
		{ label: "Sofia", value: "es-BO-SofiaNeural" },
		{ label: "Marcelo", value: "es-BO-MarceloNeural" },
	],
	"es-CL": [
		{ label: "Catalina", value: "es-CL-CatalinaNeural" },
		{ label: "Lorenzo", value: "es-CL-LorenzoNeural" },
	],
	"es-CO": [
		{ label: "Salome", value: "es-CO-SalomeNeural" },
		{ label: "Gonzalo", value: "es-CO-GonzaloNeural" },
	],
	"es-CR": [
		{ label: "Maria", value: "es-CR-MariaNeural" },
		{ label: "Juan", value: "es-CR-JuanNeural" },
	],
	"es-CU": [
		{ label: "Belkys", value: "es-CU-BelkysNeural" },
		{ label: "Manuel", value: "es-CU-ManuelNeural" },
	],
	"es-DO": [
		{ label: "Ramona", value: "es-DO-RamonaNeural" },
		{ label: "Emilio", value: "es-DO-EmilioNeural" },
	],
	"es-EC": [
		{ label: "Andrea", value: "es-EC-AndreaNeural" },
		{ label: "Luis", value: "es-EC-LuisNeural" },
	],
	"es-ES": [
		{ label: "Elvira", value: "es-ES-ElviraNeural" },
		{ label: "Alvaro", value: "es-ES-AlvaroNeural" },
		{ label: "Abril", value: "es-ES-AbrilNeural" },
		{ label: "Arnau", value: "es-ES-ArnauNeural" },
		{ label: "Elias", value: "es-ES-EliasNeural" },
		{ label: "Estrella", value: "es-ES-EstrellaNeural" },
		{ label: "Irene", value: "es-ES-IreneNeural" },
		{ label: "Laia", value: "es-ES-LaiaNeural" },
		{ label: "Lia", value: "es-ES-LiaNeural" },
		{ label: "Nil", value: "es-ES-NilNeural" },
		{ label: "Saul", value: "es-ES-SaulNeural" },
		{ label: "Teo", value: "es-ES-TeoNeural" },
		{ label: "Vera", value: "es-ES-VeraNeural" },
		{ label: "Ximena", value: "es-ES-XimenaNeural" },
		{ label: "ArabellaMultilingual", value: "es-ES-ArabellaMultilingualNeural" },
		{ label: "TristanMultilingual", value: "es-ES-TristanMultilingualNeural" },
		{ label: "XimenaMultilingual", value: "es-ES-XimenaMultilingualNeural" },
	],
	"es-GQ": [
		{ label: "Teresa", value: "es-GQ-TeresaNeural" },
		{ label: "Javier", value: "es-GQ-JavierNeural" },
	],
	"es-GT": [
		{ label: "Marta", value: "es-GT-MartaNeural" },
		{ label: "Andres", value: "es-GT-AndresNeural" },
	],
	"es-HN": [
		{ label: "Karla", value: "es-HN-KarlaNeural" },
		{ label: "Carlos", value: "es-HN-CarlosNeural" },
	],
	"es-MX": [
		{ label: "Dalia", value: "es-MX-DaliaNeural" },
		{ label: "Jorge", value: "es-MX-JorgeNeural" },
		{ label: "Beatriz", value: "es-MX-BeatrizNeural" },
		{ label: "Candela", value: "es-MX-CandelaNeural" },
		{ label: "Cecilio", value: "es-MX-CecilioNeural" },
		{ label: "Gerardo", value: "es-MX-GerardoNeural" },
		{ label: "Larissa", value: "es-MX-LarissaNeural" },
		{ label: "Liberto", value: "es-MX-LibertoNeural" },
		{ label: "Luciano", value: "es-MX-LucianoNeural" },
		{ label: "Marina", value: "es-MX-MarinaNeural" },
		{ label: "Nuria", value: "es-MX-NuriaNeural" },
		{ label: "Pelayo", value: "es-MX-PelayoNeural" },
		{ label: "Renata", value: "es-MX-RenataNeural" },
		{ label: "Yago", value: "es-MX-YagoNeural" },
	],
	"es-NI": [
		{ label: "Yolanda", value: "es-NI-YolandaNeural" },
		{ label: "Federico", value: "es-NI-FedericoNeural" },
	],
	"es-PA": [
		{ label: "Margarita", value: "es-PA-MargaritaNeural" },
		{ label: "Roberto", value: "es-PA-RobertoNeural" },
	],
	"es-PE": [
		{ label: "Camila", value: "es-PE-CamilaNeural" },
		{ label: "Alex", value: "es-PE-AlexNeural" },
	],
	"es-PR": [
		{ label: "Karina", value: "es-PR-KarinaNeural" },
		{ label: "Victor", value: "es-PR-VictorNeural" },
	],
	"es-PY": [
		{ label: "Tania", value: "es-PY-TaniaNeural" },
		{ label: "Mario", value: "es-PY-MarioNeural" },
	],
	"es-SV": [
		{ label: "Lorena", value: "es-SV-LorenaNeural" },
		{ label: "Rodrigo", value: "es-SV-RodrigoNeural" },
	],
	"es-US": [
		{ label: "Paloma", value: "es-US-PalomaNeural" },
		{ label: "Alonso", value: "es-US-AlonsoNeural" },
	],
	"es-UY": [
		{ label: "Valentina", value: "es-UY-ValentinaNeural" },
		{ label: "Mateo", value: "es-UY-MateoNeural" },
	],
	"es-VE": [
		{ label: "Paola", value: "es-VE-PaolaNeural" },
		{ label: "Sebastian", value: "es-VE-SebastianNeural" },
	],
	"et-EE": [
		{ label: "Anu", value: "et-EE-AnuNeural" },
		{ label: "Kert", value: "et-EE-KertNeural" },
	],
	"eu-ES": [
		{ label: "Ainhoa", value: "eu-ES-AinhoaNeural" },
		{ label: "Ander", value: "eu-ES-AnderNeural" },
	],
	"fa-IR": [
		{ label: "Dilara", value: "fa-IR-DilaraNeural" },
		{ label: "Farid", value: "fa-IR-FaridNeural" },
	],
	"fi-FI": [
		{ label: "Selma", value: "fi-FI-SelmaNeural" },
		{ label: "Harri", value: "fi-FI-HarriNeural" },
		{ label: "Noora", value: "fi-FI-NooraNeural" },
	],
	"fil-PH": [
		{ label: "Blessica", value: "fil-PH-BlessicaNeural" },
		{ label: "Angelo", value: "fil-PH-AngeloNeural" },
	],
	"fr-BE": [
		{ label: "Charline", value: "fr-BE-CharlineNeural" },
		{ label: "Gerard", value: "fr-BE-GerardNeural" },
	],
	"fr-CA": [
		{ label: "Sylvie", value: "fr-CA-SylvieNeural" },
		{ label: "Jean", value: "fr-CA-JeanNeural" },
		{ label: "Antoine", value: "fr-CA-AntoineNeural" },
		{ label: "Thierry", value: "fr-CA-ThierryNeural" },
	],
	"fr-CH": [
		{ label: "Ariane", value: "fr-CH-ArianeNeural" },
		{ label: "Fabrice", value: "fr-CH-FabriceNeural" },
	],
	"fr-FR": [
		{ label: "Denise", value: "fr-FR-DeniseNeural" },
		{ label: "Henri", value: "fr-FR-HenriNeural" },
		{ label: "Alain", value: "fr-FR-AlainNeural" },
		{ label: "Brigitte", value: "fr-FR-BrigitteNeural" },
		{ label: "Celeste", value: "fr-FR-CelesteNeural" },
		{ label: "Claude", value: "fr-FR-ClaudeNeural" },
		{ label: "Coralie", value: "fr-FR-CoralieNeural" },
		{ label: "Eloise", value: "fr-FR-EloiseNeural" },
		{ label: "Jacqueline", value: "fr-FR-JacquelineNeural " },
		{ label: "Jerome", value: "fr-FR-JeromeNeural" },
		{ label: "Josephine", value: "fr-FR-JosephineNeural" },
		{ label: "Maurice", value: "fr-FR-MauriceNeural" },
		{ label: "RemyMultilingual", value: "fr-FR-RemyMultilingualNeural" },
		{ label: "VivienneMultilingual", value: "fr-FR-VivienneMultilingualNeural" },
		{ label: "Yves", value: "fr-FR-YvesNeural" },
		{ label: "Yvette", value: "fr-FR-YvetteNeural" },
		{ label: "LucienMultilingual", value: "fr-FR-LucienMultilingualNeural" },
	],
	"ga-IE": [
		{ label: "Orla", value: "ga-IE-OrlaNeural" },
		{ label: "Colm", value: "ga-IE-ColmNeural" },
	],
	"gl-ES": [
		{ label: "Sabela", value: "gl-ES-SabelaNeural" },
		{ label: "Roi", value: "gl-ES-RoiNeural" },
	],
	"gu-IN": [
		{ label: "Dhwani", value: "gu-IN-DhwaniNeural" },
		{ label: "Niranjan", value: "gu-IN-NiranjanNeural" },
	],
	"he-IL": [
		{ label: "Hila", value: "he-IL-HilaNeural" },
		{ label: "Avri", value: "he-IL-AvriNeural" },
	],
	"hi-IN": [
		{ label: "Swara", value: "hi-IN-SwaraNeural" },
		{ label: "Madhur", value: "hi-IN-MadhurNeural" },
		{ label: "Aarav", value: "hi-IN-AaravNeural" },
		{ label: "Ananya", value: "hi-IN-AnanyaNeural" },
		{ label: "Kavya", value: "hi-IN-KavyaNeural" },
		{ label: "Kunal", value: "hi-IN-KunalNeural" },
		{ label: "Rehaan", value: "hi-IN-RehaanNeural" },
	],
	"hr-HR": [
		{ label: "Gabrijela", value: "hr-HR-GabrijelaNeural" },
		{ label: "Srecko", value: "hr-HR-SreckoNeural" },
	],
	"hu-HU": [
		{ label: "Noemi", value: "hu-HU-NoemiNeural" },
		{ label: "Tamas", value: "hu-HU-TamasNeural" },
	],
	"hy-AM": [
		{ label: "Anahit", value: "hy-AM-AnahitNeural" },
		{ label: "Hayk", value: "hy-AM-HaykNeural" },
	],
	"id-ID": [
		{ label: "Gadis", value: "id-ID-GadisNeural" },
		{ label: "Ardi", value: "id-ID-ArdiNeural" },
	],
	"is-IS": [
		{ label: "Gudrun", value: "is-IS-GudrunNeural" },
		{ label: "Gunnar", value: "is-IS-GunnarNeural" },
	],
	"it-IT": [
		{ label: "Elsa", value: "it-IT-ElsaNeural" },
		{ label: "Isabella", value: "it-IT-IsabellaNeural" },
		{ label: "Diego", value: "it-IT-DiegoNeural" },
		{ label: "Benigno", value: "it-IT-BenignoNeural" },
		{ label: "Cataldo", value: "it-IT-CataldoNeural" },
		{ label: "Fabiola", value: "it-IT-FabiolaNeural" },
		{ label: "Fiamma", value: "it-IT-FiammaNeural" },
		{ label: "Gianni", value: "it-IT-GianniNeural" },
		{ label: "Giuseppe", value: "it-IT-GiuseppeNeural" },
		{ label: "Imelda", value: "it-IT-ImeldaNeural" },
		{ label: "Irma", value: "it-IT-IrmaNeural" },
		{ label: "Lisandro", value: "it-IT-LisandroNeural" },
		{ label: "Palmira", value: "it-IT-PalmiraNeural" },
		{ label: "Pierina", value: "it-IT-PierinaNeural" },
		{ label: "Rinaldo", value: "it-IT-RinaldoNeural" },
		{ label: "GiuseppeMultilingual", value: "it-IT-GiuseppeMultilingualNeural" },
		{ label: "IsabellaMultilingual", value: "it-IT-IsabellaMultilingualNeural" },
		{ label: "MarcelloMultilingual", value: "it-IT-MarcelloMultilingualNeural" },
	],
	"CANS-CA": [
		{ label: "Siqiniq (Syllabics)", value: "iu-Cans-CA-SiqiniqNeural" },
		{ label: "Taqqiq (Syllabics)", value: "iu-Cans-CA-TaqqiqNeura" },
	],
	"LATN-CA": [
		{ label: "Siqiniq (Latin)", value: "iu-Latn-CA-SiqiniqNeural" },
		{ label: "Taqqiq (Latin)", value: "iu-Latn-CA-TaqqiqNeura" },
	],
	"ja-JP": [
		{ label: "Nanami", value: "ja-JP-NanamiNeural" },
		{ label: "Keita", value: "ja-JP-KeitaNeural" },
		{ label: "Aoi", value: "ja-JP-AoiNeural" },
		{ label: "Daichi", value: "ja-JP-DaichiNeural" },
		{ label: "Naoki", value: "ja-JP-NaokiNeural" },
		{ label: "Shiori", value: "ja-JP-ShioriNeural" },
		{ label: "MasaruMultilingual", value: "ja-JP-MasaruMultilingualNeural" },
	],
	"jv-ID": [
		{ label: "Siti", value: "jv-ID-SitiNeural" },
		{ label: "Dimas", value: "jv-ID-DimasNeural" },
	],
	"ka-GE": [
		{ label: "Eka", value: "ka-GE-EkaNeural" },
		{ label: "Giorgi", value: "ka-GE-GiorgiNeural" },
	],
	"kk-KZ": [
		{ label: "Aigul", value: "kk-KZ-AigulNeural" },
		{ label: "Daulet", value: "kk-KZ-DauletNeural" },
	],
	"km-KH": [
		{ label: "Sreymom", value: "km-KH-SreymomNeural" },
		{ label: "Piseth", value: "km-KH-PisethNeural" },
	],
	"kn-IN": [
		{ label: "Sapna", value: "kn-IN-SapnaNeural" },
		{ label: "Gagan", value: "kn-IN-GaganNeural" },
	],
	"ko-KR": [
		{ label: "SunHi", value: "ko-KR-SunHiNeural" },
		{ label: "InJoon", value: "ko-KR-InJoonNeural" },
		{ label: "BongJin", value: "ko-KR-BongJinNeural" },
		{ label: "GookMin", value: "ko-KR-GookMinNeural" },
		{ label: "JiMin", value: "ko-KR-JiMinNeural" },
		{ label: "SeoHyeon", value: "ko-KR-SeoHyeonNeural" },
		{ label: "SoonBok", value: "ko-KR-SoonBokNeural" },
		{ label: "YuJin", value: "ko-KR-YuJinNeural" },
		{ label: "HyunsuMultilingual", value: "ko-KR-HyunsuMultilingualNeural" },
	],
	"lo-LA": [
		{ label: "Keomany", value: "lo-LA-KeomanyNeural" },
		{ label: "Chanthavong", value: "lo-LA-ChanthavongNeural" },
	],
	"lt-LT": [
		{ label: "Ona", value: "lt-LT-OnaNeural" },
		{ label: "Leonas", value: "lt-LT-LeonasNeural" },
	],
	"lv-LV": [
		{ label: "Everita", value: "lv-LV-EveritaNeural" },
		{ label: "Nils", value: "lv-LV-NilsNeural" },
	],
	"mk-MK": [
		{ label: "Marija", value: "mk-MK-MarijaNeural" },
		{ label: "Aleksandar", value: "mk-MK-AleksandarNeural" },
	],
	"ml-IN": [
		{ label: "Sobhana", value: "ml-IN-SobhanaNeural" },
		{ label: "Midhun", value: "ml-IN-MidhunNeural" },
	],
	"mn-MN": [
		{ label: "Yesui", value: "mn-MN-YesuiNeural" },
		{ label: "Bataa", value: "mn-MN-BataaNeural" },
	],
	"mr-IN": [
		{ label: "Aarohi", value: "mr-IN-AarohiNeural" },
		{ label: "Manohar", value: "mr-IN-ManoharNeural" },
	],
	"ms-MY": [
		{ label: "Yasmin", value: "ms-MY-YasminNeural" },
		{ label: "Osman", value: "ms-MY-OsmanNeural" },
	],
	"mt-MT": [
		{ label: "Grace", value: "mt-MT-GraceNeural" },
		{ label: "Joseph", value: "mt-MT-JosephNeural" },
	],
	"my-MM": [
		{ label: "Nilar", value: "my-MM-NilarNeural" },
		{ label: "Thiha", value: "my-MM-ThihaNeural" },
	],
	"nb-NO": [
		{ label: "Pernille", value: "nb-NO-PernilleNeural" },
		{ label: "Finn", value: "nb-NO-FinnNeural" },
		{ label: "Iselin", value: "nb-NO-IselinNeural" },
	],
	"ne-NP": [
		{ label: "Hemkala", value: "ne-NP-HemkalaNeural" },
		{ label: "Sagar", value: "ne-NP-SagarNeural" },
	],
	"nl-BE": [
		{ label: "Dena", value: "nl-BE-DenaNeural" },
		{ label: "Arnaud", value: "nl-BE-ArnaudNeural" },
	],
	"nl-NL": [
		{ label: "Fenna", value: "nl-NL-FennaNeural" },
		{ label: "Maarten", value: "nl-NL-MaartenNeural" },
		{ label: "Colette", value: "nl-NL-ColetteNeural" },
	],
	"or-IN": [
		{ label: "Subhasini", value: "or-IN-SubhasiniNeural" },
		{ label: "Sukant", value: "or-IN-SukantNeural" },
	],
	"pa-IN": [
		{ label: "Ojas", value: "pa-IN-OjasNeural" },
		{ label: "Vaani", value: "pa-IN-VaaniNeural" },
	],
	"pl-PL": [
		{ label: "Agnieszka", value: "pl-PL-AgnieszkaNeural" },
		{ label: "Marek", value: "pl-PL-MarekNeural" },
		{ label: "Zofia", value: "pl-PL-ZofiaNeural" },
	],
	"ps-AF": [
		{ label: "Latifa", value: "ps-AF-LatifaNeural" },
		{ label: "GulNawaz", value: "ps-AF-GulNawazNeural" },
	],
	"pt-BR": [
		{ label: "Francisca", value: "pt-BR-FranciscaNeural" },
		{ label: "Antonio", value: "pt-BR-AntonioNeural" },
		{ label: "Brenda", value: "pt-BR-BrendaNeural" },
		{ label: "Donato", value: "pt-BR-DonatoNeural" },
		{ label: "Elza", value: "pt-BR-ElzaNeural" },
		{ label: "Fabio", value: "pt-BR-FabioNeural" },
		{ label: "Giovanna", value: "pt-BR-GiovannaNeural" },
		{ label: "Humberto", value: "pt-BR-HumbertoNeural" },
		{ label: "Julio", value: "pt-BR-JulioNeural" },
		{ label: "Leila", value: "pt-BR-LeilaNeural" },
		{ label: "Leticia", value: "pt-BR-LeticiaNeural" },
		{ label: "Manuela", value: "pt-BR-ManuelaNeural" },
		{ label: "Nicolau", value: "pt-BR-NicolauNeural" },
		{ label: "Thalita", value: "pt-BR-ThalitaNeural" },
		{ label: "Valerio", value: "pt-BR-ValerioNeural" },
		{ label: "Yara", value: "pt-BR-YaraNeural" },
		{ label: "MacerioMultilingual", value: "pt-BR-MacerioMultilingualNeural" },
		{ label: "ThalitaMultilingual", value: "pt-BR-ThalitaMultilingualNeural" },
	],
	"pt-PT": [
		{ label: "Raquel", value: "pt-PT-RaquelNeural" },
		{ label: "Duarte", value: "pt-PT-DuarteNeural" },
		{ label: "Fernanda", value: "pt-PT-FernandaNeural" },
	],
	"ro-RO": [
		{ label: "Alina", value: "ro-RO-AlinaNeural" },
		{ label: "Emil", value: "ro-RO-EmilNeural" },
	],
	"ru-RU": [
		{ label: "Svetlana", value: "ru-RU-SvetlanaNeural" },
		{ label: "Dmitry", value: "ru-RU-DmitryNeural" },
		{ label: "Dariya", value: "ru-RU-DariyaNeural" },
	],
	"si-LK": [
		{ label: "Thilini", value: "si-LK-ThiliniNeural" },
		{ label: "Sameera", value: "si-LK-SameeraNeural" },
	],
	"sk-SK": [
		{ label: "Viktoria", value: "sk-SK-ViktoriaNeural" },
		{ label: "Lukas", value: "sk-SK-LukasNeural" },
	],
	"sl-SI": [
		{ label: "Petra", value: "sl-SI-PetraNeural" },
		{ label: "Rok", value: "sl-SI-RokNeural" },
	],
	"so-SO": [
		{ label: "Ubax", value: "so-SO-UbaxNeural" },
		{ label: "Muuse", value: "so-SO-MuuseNeural" },
	],
	"sq-AL": [
		{ label: "Anila", value: "sq-AL-AnilaNeural" },
		{ label: "Ilir", value: "sq-AL-IlirNeural" },
	],
	"LATN-RS": [
		{ label: "Nicholas (Latin, Serbia)", value: "sr-Latn-RS-NicholasNeural" },
		{ label: "SophieNeural (Latin, Serbia)", value: "sr-Latn-RS-SophieNeural" },
	],
	"sr-RS": [
		{ label: "Sophie (Cyrillic, Serbia)", value: "sr-RS-SophieNeural" },
		{ label: "Nicholas (Cyrillic, Serbia)", value: "sr-RS-NicholasNeural" },
	],
	"su-ID": [
		{ label: "Tuti", value: "su-ID-TutiNeural" },
		{ label: "Jajang", value: "su-ID-JajangNeural" },
	],
	"sv-SE": [
		{ label: "Sofie", value: "sv-SE-SofieNeural" },
		{ label: "Mattias", value: "sv-SE-MattiasNeural" },
		{ label: "Hillevi", value: "sv-SE-HilleviNeural" },
	],
	"sw-KE": [
		{ label: "Zuri", value: "sw-KE-ZuriNeural" },
		{ label: "Rafiki", value: "sw-KE-RafikiNeural" },
	],
	"sw-TZ": [
		{ label: "Rehema", value: "sw-TZ-RehemaNeural" },
		{ label: "Daudi", value: "sw-TZ-DaudiNeural" },
	],
	"ta-IN": [
		{ label: "Pallavi", value: "ta-IN-PallaviNeural" },
		{ label: "Valluvar", value: "ta-IN-ValluvarNeural" },
	],
	"ta-LK": [
		{ label: "Saranya", value: "ta-LK-SaranyaNeural" },
		{ label: "Kumar", value: "ta-LK-KumarNeural" },
	],
	"ta-MY": [
		{ label: "Kani", value: "ta-MY-KaniNeural" },
		{ label: "Surya", value: "ta-MY-SuryaNeural" },
	],
	"ta-SG": [
		{ label: "Venba", value: "ta-SG-VenbaNeural" },
		{ label: "Anbu", value: "ta-SG-AnbuNeural" },
	],
	"te-IN": [
		{ label: "Shruti", value: "te-IN-ShrutiNeural" },
		{ label: "Mohan", value: "te-IN-MohanNeural" },
	],
	"th-TH": [
		{ label: "Premwadee", value: "th-TH-PremwadeeNeural" },
		{ label: "Niwat", value: "th-TH-NiwatNeural" },
		{ label: "Achara", value: "th-TH-AcharaNeural" },
	],
	"tr-TR": [
		{ label: "Emel", value: "tr-TR-EmelNeural" },
		{ label: "Ahmet", value: "tr-TR-AhmetNeural" },
	],
	"uk-UA": [
		{ label: "Polina", value: "uk-UA-PolinaNeural" },
		{ label: "Ostap", value: "uk-UA-OstapNeural" },
	],
	"ur-IN": [
		{ label: "Gul", value: "ur-IN-GulNeural" },
		{ label: "Salman", value: "ur-IN-SalmanNeural" },
	],
	"ur-PK": [
		{ label: "Uzma", value: "ur-PK-UzmaNeural" },
		{ label: "Asad", value: "ur-PK-AsadNeural" },
	],
	"uz-UZ": [
		{ label: "Madina", value: "uz-UZ-MadinaNeural" },
		{ label: "Sardor", value: "uz-UZ-SardorNeural" },
	],
	"vi-VN": [
		{ label: "HoaiMy", value: "vi-VN-HoaiMyNeural" },
		{ label: "NamMinh", value: "vi-VN-NamMinhNeural" },
	],
	"wuu-CN": [
		{ label: "Xiaotong", value: "wuu-CN-XiaotongNeural" },
		{ label: "Yunzhe", value: "wuu-CN-YunzheNeural" },
	],
	"yue-CN": [
		{ label: "XiaoMin", value: "yue-CN-XiaoMinNeural" },
		{ label: "YunSong", value: "yue-CN-YunSongNeural" },
	],
	"zh-CN": [
		{ label: "Xiaoxiao", value: "zh-CN-XiaoxiaoNeural" },
		{ label: "Yunxi", value: "zh-CN-YunxiNeural" },
		{ label: "Yunjian", value: "zh-CN-YunjianNeural" },
		{ label: "Xiaoyi", value: "zh-CN-XiaoyiNeural" },
		{ label: "Yunyang", value: "zh-CN-YunyangNeural " },
		{ label: "Xiaochen", value: "zh-CN-XiaochenNeural" },
		{ label: "XiaochenMultilingual", value: "zh-CN-XiaochenMultilingualNeural" },
		{ label: "Xiaohan", value: "zh-CN-XiaohanNeural" },
		{ label: "Xiaomo", value: "zh-CN-XiaomoNeural" },
		{ label: "Xiaoqiu", value: "zh-CN-XiaoqiuNeural" },
		{ label: "Xiaorou", value: "zh-CN-XiaorouNeural" },
		{ label: "Xiaorui", value: "zh-CN-XiaoruiNeural" },
		{ label: "Xiaoshuang", value: "zh-CN-XiaoshuangNeural" },
		{ label: "XiaoxiaoDialects", value: "zh-CN-XiaoxiaoDialectsNeural" },
		{ label: "XiaoxiaoMultilingual", value: "zh-CN-XiaoxiaoMultilingualNeural" },
		{ label: "Xiaoyan", value: "zh-CN-XiaoyanNeural" },
		{ label: "Xiaoyou", value: "zh-CN-XiaoyouNeural" },
		{ label: "XiaoyuMultilingual", value: "zh-CN-XiaoyuMultilingualNeural" },
		{ label: "Xiaozhen", value: "zh-CN-XiaozhenNeural" },
		{ label: "Yunfeng", value: "zh-CN-YunfengNeural" },
		{ label: "Yunhao", value: "zh-CN-YunhaoNeural" },
		{ label: "Yunxia", value: "zh-CN-YunxiaNeural" },
		{ label: "Yunye", value: "zh-CN-YunyeNeural" },
		{ label: "YunyiMultilingual", value: "zh-CN-YunyiMultilingualNeural" },
		{ label: "YunfanMultilingual", value: "zh-CN-YunfanMultilingualNeural" },
		{ label: "YunxiaoMultilingual", value: "zh-CN-YunxiaoMultilingualNeural" },
	],
	"zh-CN-GUANGXI": [{ label: "Yunqi", value: "zh-CN-guangxi-YunqiNeural" }],
	"zh-CN-henan": [{ label: "Yundeng", value: "zh-CN-henan-YundengNeural" }],
	"zh-CN-liaoning": [
		{ label: "Xiaobei", value: "zh-CN-liaoning-XiaobeiNeural" },
		{ label: "Yunbiao", value: "zh-CN-liaoning-YunbiaoNeural" },
	],
	"zh-CN-shaanxi": [{ label: "Xiaoni", value: "zh-CN-shaanxi-XiaoniNeural" }],
	"CN-shandong": [{ label: "Yunxiang", value: "zh-CN-shandong-YunxiangNeural" }],
	"CN-sichuan": [{ label: "Yunxi", value: "zh-CN-sichuan-YunxiNeural" }],
	"zh-HK": [
		{ label: "HiuMaan", value: "zh-HK-HiuMaanNeural" },
		{ label: "WanLung", value: "zh-HK-WanLungNeural" },
		{ label: "HiuGaai", value: "zh-HK-HiuGaaiNeural" },
	],
	"zh-TW": [
		{ label: "HsiaoChen", value: "zh-TW-HsiaoChenNeural" },
		{ label: "YunJhe", value: "zh-TW-YunJheNeural" },
		{ label: "HsiaoYu", value: "zh-TW-HsiaoYuNeural" },
	],
	"zu-ZA": [
		{ label: "Thando", value: "zu-ZA-ThandoNeural" },
		{ label: "Themba", value: "zu-ZA-ThembaNeural" },
	],
};
