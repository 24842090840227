import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";

import FormHelperText from "@mui/material/FormHelperText";
import dayjs from "dayjs";
import { ThemeProvider } from "@mui/material/styles";
import { DateTimePickerTheme } from "./theme";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MenuList } from "@mui/material";
import MuiButton from "@mui/material/Button";
import MuiDialog from "@mui/material/Dialog";
import MuiCheckbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import colorPicker from "../../Images/colorPicker.png";
import { ArrowDownIcon, CloseIcon, EyeCloseIcon, EyeIcon, UploadIcon, ColorIcon } from "../../services/icons";

import TableMui from "./GenericTable";
import no_data_found from "../../Images/no_data_found.svg";
import theme, { inputClasses, buttonClasses, textClasses, cardClasses, autocompleteTheme } from "./theme";
import { capitalizeCamelCase, debounce, fakeFileInput } from "../../services/functions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./util.css";
import { includes_special_char } from "../../services/validation";

const loaderSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
			<path
				fill="var(--gray-color)"
				d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
			>
				<animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
			</path>
		</svg>
	);
};

const adornmentIcon = {
	eyeClose: <EyeCloseIcon />,
	eye: <EyeIcon />,
	CloseIcon: <CloseIcon htmlColor="var(--gray-color)" />,
	loader: loaderSvg(),
	UploadIcon: <UploadIcon />,
};

const CustomSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "var(--primary-color)",
		"&:hover": {
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "var(--primary-color)",
	},
}));
// const TCell = ({ text = "-", color = "", cursor = "", onClick }) => {
// 	<Typography
// 		sx={{
// 			...textClasses.t13n,
// 			textAlign: "center",
// 			color,
// 			cursor,
// 		}}
// 	>
// 		{text}
// 	</Typography>;
// };

function ActionMenu({ id, handlers = {}, childMenuOpen = [], childMenu, handleChildMenuClick }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorSubMenu, setAnchorSubMenu] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (e) => {
		// console.log("action open", e);
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setAnchorSubMenu(null);
	};

	// console.log('secondMenu ', id);
	let items = [];
	for (let key in handlers) {
		// console.log(key, isHandleClose, isHandleClose.includes(key));
		items.push(
			<MenuItem
				onClick={(e) => {
					handlers[key](id);
					if (childMenuOpen.includes(key) != true) {
						handleClose();
					} else {
						setAnchorSubMenu(e.currentTarget);
					}
				}}
				key={key}
			>
				{capitalizeCamelCase(key)}
			</MenuItem>
		);
	}
	return (
		<>
			{tCell("Action", theme.themeOrange, "pointer", handleClick)}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{items}
				<Menu
					id="basic-menu"
					anchorEl={anchorSubMenu}
					open={Boolean(anchorSubMenu)}
					onClose={handleClose}
					sx={{
						"& .MuiMenu-list": {
							padding: "4px 0",
							maxHeight: "400px",
						},
					}}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					anchorOrigin={{
						vertical: "top",
						horizontal: "left", // Corrected
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<MenuList>
						{childMenu?.length > 0 ? (
							childMenu.map((item) => (
								<MenuItem
									key={item.label}
									onClick={(e) => {
										handleChildMenuClick({ ...item, index: id });
										handleClose();
									}}
								>
									{item.label}
								</MenuItem>
							))
						) : (
							<MenuItem>Loading.....</MenuItem>
						)}
					</MenuList>
				</Menu>
			</Menu>
		</>
	);
}
function Switcher({ id, name, checked = false, onChange, label = "", disabled = false, readOnly = false, size = "small", value = "" }) {
	return (
		<span className="UM_switch-container">
			<CustomSwitch disabled={disabled} size={size} checked={checked} onChange={onChange} name={name} inputProps={{ "aria-label": "controlled" }} />
			<label className="labeltext">{label}</label>
		</span>
	);
}
function Button({ onClick, testId, variant = "contained", disabled = false, text, style = {}, type = "button", children }) {
	return (
		<MuiButton
			className="UM_uploadfile-btn"
			sx={{
				width: "100%",
				...style,
				textTransform: "none",
			}}
			size="small"
			variant={variant}
			data-testid={testId}
			disabled={disabled}
			onClick={onClick}
			type={type}
		>
			{text}
			{children}
		</MuiButton>
	);
}
function Checkbox({ id, name, checked = false, onChange, label = "", disabled = false, readOnly = false, size = "small", value = "" }) {
	return (
		<>
			<FormControlLabel
				control={
					<MuiCheckbox
						disabled={disabled}
						size={size}
						checked={checked}
						onChange={onChange}
						value={value}
						name={name}
						sx={{
							...inputClasses.checkbox,
						}}
					/>
				}
				label={<Typography style={{ wordBreak: "break-all" }}>{label}</Typography>}
				style={{ color: "var(--text-color)" }}
			/>
		</>
	);
}

function PopOver({ children, id, title, anchorEl, open, onClose, placement = "bottom" }) {
	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
		>
			<Box sx={{ p: 2 }}>
				<Typography variant="h6" gutterBottom>
					{title}
				</Typography>
				{children}
			</Box>
		</Popover>
	);
}

function DataNotFound() {
	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				<img src={no_data_found} height="250px" width="250px" />
				<Typography sx={{ color: "var(--text-color)" }}>No Data Found</Typography>
			</Box>
		</>
	);
}
function DatePicker({ name, label, value, minDATE, maxDATE, onChange, disabled = false, isRequired = false, format = "DD/MM/YYYY" }) {
	return (
		<ThemeProvider theme={DateTimePickerTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MuiDatePicker
					className="customDatePicker"
					sx={{ ...inputClasses.shadowField, fontSize: "8px", width: "100%" }}
					slotProps={{ textField: { size: "small" } }}
					format={format}
					name={name}
					value={value}
					onChange={onChange}
					label={label}
					disabled={disabled}
					minDate={minDATE}
					maxDate={maxDATE}
					isRequired={isRequired}
				/>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
function TimePicker({ name, label, value, onChange, disabled = false, isRequired = false }) {
	return (
		<ThemeProvider theme={DateTimePickerTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MuiTimePicker
					className="customTimePicker"
					sx={{ ...inputClasses.shadowField, fontSize: "8px", width: "100%" }}
					slotProps={{ textField: { size: "small" } }}
					name={name}
					value={value}
					onChange={onChange}
					label={label}
					disabled={disabled}
					timeSteps={{ minutes: 1 }}
					isRequired={isRequired}
				/>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
function DateTimePicker({ disabled = false, label, name, onChange, value, minDATE, maxDATE }) {
	// const maxDate = dayjs(new Date()).format('YYYY-MM-DD');
	// const minDate = minDATE ? dayjs(minDATE).format('YYYY-MM-DD') : null;
	const today = dayjs("2024-04-09T18:30:00.000Z");
	return (
		<ThemeProvider theme={DateTimePickerTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MuiDateTimePicker
					disabled={disabled}
					label={label}
					name={name}
					onChange={onChange}
					size="small"
					sx={{ ...inputClasses.shadowField }}
					slotProps={{ textField: { size: "small" } }}
					value={value}
					minDate={minDATE}
					maxDate={maxDATE}
					// disableFuture
					// maxDate={new Date()}
				/>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

function DetailCard({ isOpen, onClose, title, children }) {
	return (
		<div className={`detail_card ${isOpen ? "open" : ""}`}>
			{isOpen && (
				<Card sx={{ ...cardClasses.basic, borderRadius: "8px", border: `1px solid ${theme.themeOrange}` }}>
					<CardHeader
						sx={{ height: "46px", backgroundColor: theme.themeOrange }}
						title={
							<Typography
								sx={{
									...textClasses.boldText,
									color: "white",
									fontSize: "16px",
								}}
							>
								{title}
							</Typography>
						}
						action={
							<IconButton size="small" sx={{ p: 0, mb: 1 }} onClick={onClose}>
								<CloseIcon sx={{ color: "white" }} />
							</IconButton>
						}
					/>
					<CardContent sx={{ height: "55vh", overflowY: "auto", overflowX: "hidden", backgroundColor: "var(--background-color)" }}>{children}</CardContent>
				</Card>
			)}
		</div>
	);
}
function Dialog({ open, handleClose, handleCancel, handleSubmit, title, size = "sm", children, noBtn = false, isSubmitDisabled = false, CancelText = "Cancel", SubmitText = "Submit" }) {
	return (
		<MuiDialog fullWidth maxWidth={size} onClose={handleClose} aria-labelledby="dialog-title" open={open}>
			<DialogTitle sx={{ m: 0, p: 2, backgroundColor: "var(--model-bg-color)", color: "var(--text-color)" }} id="dialog-title">
				{title}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: "rgb(158, 158, 158)",
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers sx={{ backgroundColor: "var(--model-bg-color)", color: "var(--text-color)" }}>
				{children}
			</DialogContent>
			{noBtn ? null : (
				<DialogActions sx={{ backgroundColor: "var(--model-bg-color)", color: "var(--text-color)" }}>
					<Button autoFocus onClick={handleCancel ? handleCancel : handleClose} text={CancelText} style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fitContent" }} />
					<Button autoFocus onClick={handleSubmit} text={SubmitText} style={{ ...buttonClasses.lynkitOrangeFill, width: "fitContent" }} disabled={isSubmitDisabled} />
				</DialogActions>
			)}
		</MuiDialog>
	);
}

function Dropdown({
	id = "",
	name = "",
	value = null,
	options,
	onInputChange,
	onChange,
	handleCustomKeyDown,
	label,
	dataTestId = "",
	disabled = false,
	getOptionDisabled,
	error = "",
	required = false,
	multiple = false,
	readonly = false,
	inputRef = null,
	openOnFocus = false,
	enableCustomValue = false,
	helperText = "",
}) {
	const [dropdownOptions, setDropdownOptions] = useState(options);

	const handleKeyDown = (event) => {
		// console.log("handleKeyDown=========================>", event);

		if (event.key === "Enter" && event.target.value) {
			const newOption = { label: event.target.value, value: event.target.value };
			if (!dropdownOptions.some((option) => option.value === newOption.value)) {
				setDropdownOptions([...dropdownOptions, newOption]);
				if (multiple) {
					onChange([...(Array.isArray(value) ? value : []), newOption], name);
				} else {
					onChange(newOption, name);
					handleCustomKeyDown(event);
				}
			}
		}
	};
	const handleCustomKeyDownWrapper = enableCustomValue ? handleKeyDown : () => {};
	return (
		<FormControl variant="outlined" size="small" fullWidth>
			<ThemeProvider theme={autocompleteTheme}>
				<Autocomplete
					id={id}
					size="small"
					data-testid={dataTestId}
					sx={{ "& fieldset": { borderRadius: "12px" } }}
					options={enableCustomValue ? dropdownOptions : options}
					name={name}
					onChange={(e, newVal) => onChange(newVal, name)}
					onInputChange={(e, newInputValue, reason) => {
						// console.log(e, newInputValue, reason)
						if (e?.type == "change") {
							onInputChange && onInputChange({ target: { value: newInputValue } });
						}
					}}
					disabled={disabled}
					readOnly={readonly}
					error={error}
					onKeyDown={handleCustomKeyDownWrapper}
					renderInput={(params) => (
						<TextField
							sx={{
								...inputClasses.textField,
							}}
							{...params}
							label={label}
							required={required}
							error={!!error}
							helperText={error || ""}
							inputRef={inputRef}
						/>
					)}
					renderOption={(props, option) => {
						return (
							<li
								{...props}
								key={`${props.key}-${props.id}-${props["data-option-index"]}`}
								style={{
									color: option.isCreateOption ? "#ff7200" : "var(--text-color)",
									fontSize: "14px",
								}}
							>
								{option.label}
							</li>
						);
					}}
					value={value}
					getOptionLabel={(option) => option?.label || ""}
					getOptionDisabled={getOptionDisabled}
					isOptionEqualToValue={(option, value) => {
						return (option?.value == "" && value == "") || option?.value == value?.value;
					}}
					multiple={multiple}
					openOnFocus={openOnFocus}
				/>
				<FormHelperText>{helperText}</FormHelperText>
			</ThemeProvider>
		</FormControl>
	);
}
function GenericTable({ header, rows, pageCount, TableData, pageNo, onPageChange, onPageSizeChange, limit, isLoading, IsScroll, openRows, openRowsData }) {
	// console.log({header,
	// 	rows,
	// 	pageCount,
	// 	pageNo,
	// 	onPageChange,
	// 	onPageSizeChange,
	// 	limit,})
	return (
		<TableMui
			header={header}
			rows={rows}
			TableData={TableData}
			pageCount={pageCount}
			pageNumber={pageNo}
			handleChangePage={onPageChange}
			handleChangeRowsPerPage={onPageSizeChange}
			rowsPerPage={limit}
			isLoading={isLoading}
			IsScroll={IsScroll}
			openRows={openRows}
			openRowsData={openRowsData}
		/>
	);
}

function Input({
	id = "",
	name,
	value,
	onChange,
	type = "text",
	label = "",
	placeholder = "",
	min = "",
	max = "",
	step = "",
	readOnly = false,
	disabled = false,
	required = false,
	children,
	adornment = {
		is: false,
	},
	error = "",
	sx = {},
	regex = "",
}) {
	return (
		<TextField
			size="small"
			variant="outlined"
			sx={{ ...inputClasses.textField, ...sx }}
			InputProps={{
				sx: { ...inputClasses.textField },
				endAdornment: adornment?.is ? (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={adornment.onClick}
							// onMouseDown={handleMouseDownPassword}
							edge="end"
							sx={{ color: "var(--gray-color)" }}
						>
							{adornmentIcon[adornment.type]}
						</IconButton>
					</InputAdornment>
				) : null,
				readOnly: readOnly,
				// min :{min}
			}}
			type={type}
			name={name}
			value={value}
			onChange={(e) => {
				onChange(e);
			}}
			label={label}
			placeholder={placeholder}
			required={required}
			id={id}
			disabled={disabled}
			error={!!error}
			helperText={error || ""}
			inputProps={{ regex, min, max }}
		/>
	);
}
function Loader({ size = "", height = "" }) {
	return (
		<div className="loaderContainer" style={{ height }}>
			<i className="fa fa-spinner fa-spin spinner" style={{ fontSize: size, color: "var(--primary-color)" }}></i>
		</div>
	);
}
function SearchDropdown({
	id = "",
	name = "",
	value = "",
	preSelectedOption = null,
	options = [],
	onChange,
	onDropdownChange,
	onhandleClear,
	type = "text",
	label,
	placeholder = "",
	dataTestId = "",
	disabled = false,
	error = "",
	required = false,
	multiple = false,
	Debounce = false,
	textBorderRadius = "1px 12px 12px 1px",
	selectBorderRadius = "12px 1px 1px 12px",
	adornment = {
		is: false,
	},
}) {
	const [selectedOption, setselectedOption] = useState(preSelectedOption);
	// let selectedOption = { }
	// const dropdown = options && options.length > 0 && options.map((ele) => ({'label': capitalizeSentence(ele), 'value': ele }))
	// options = options.map( x => ({"label": x, "value": x}))
	const onhandleDropdown = (newVal, name) => {
		// console.log("selectdropdown", newVal);
		// selectedOption = newVal
		setselectedOption(newVal);
		if (onDropdownChange) {
			onDropdownChange(newVal, name);
		}
		if (newVal === null) {
			onhandleClear();
		}
	};
	const onhandleInput = (value) => {
		if (selectedOption && selectedOption.value != "" && Debounce === true) {
			debounce(onChange(value, selectedOption.value), 500);
		} else {
			onChange(value, selectedOption?.value);
		}
	};
	return (
		<div style={{ display: "flex", width: "100%" }}>
			<div style={{ display: "flex", width: "50%" }}>
				<FormControl variant="outlined" size="small" fullWidth>
					<ThemeProvider theme={autocompleteTheme}>
						<Autocomplete
							size="small"
							data-testid={dataTestId}
							sx={{ "& fieldset": { borderRadius: selectBorderRadius } }}
							options={options.length > 0 ? options : []}
							// name={name}
							onChange={(e, newVal) => onhandleDropdown(newVal, name)}
							disabled={disabled}
							error={error}
							renderInput={(params) => (
								<TextField
									sx={{
										...inputClasses.textField,
									}}
									{...params}
									label={label}
									required={required}
									error={!!error}
									helperText={error || ""}
								/>
							)}
							renderOption={(props, option) => {
								return (
									<li
										{...props}
										key={`${props.key}-${props.id}-${props["data-option-index"]}`}
										style={{
											color: option.isCreateOption ? "#ff7200" : "var(--text-color)",
											fontSize: "14px",
										}}
									>
										{option.label}
									</li>
								);
							}}
							value={selectedOption}
							isOptionEqualToValue={(option, value) => {
								return (option?.value == "" && value == "") || option?.value == value?.value || option === value;
							}}
							multiple={multiple}
						/>
					</ThemeProvider>
				</FormControl>
			</div>
			<div style={{ display: "flex", width: "50%" }}>
				<FormControl variant="outlined" size="small" fullWidth>
					<TextField
						size="small"
						variant="outlined"
						sx={{ ...inputClasses.textField }}
						InputProps={{
							sx: { ...inputClasses.textField, borderRadius: textBorderRadius },
							endAdornment: adornment?.is ? (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={adornment.onClick}
										// onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{adornmentIcon[adornment.type]}
									</IconButton>
								</InputAdornment>
							) : null,
						}}
						type={type}
						// name={name}
						value={value[selectedOption?.value] ? value[selectedOption?.value] : ""}
						onChange={(e) => onhandleInput(e.target.value)}
						// label={label}
						placeholder={placeholder}
						required={required}
						id={id}
						disabled={disabled}
						error={!!error}
						helperText={error || ""}
					/>
				</FormControl>
			</div>
		</div>
	);
}
function SearchInput({ onChange, value = "", placeholder }) {
	return (
		<FormControl fullWidth variant="outlined" size="small" sx={{ ...inputClasses.themeBoundary }}>
			<OutlinedInput
				sx={{
					fontSize: "12px",
					...inputClasses.themeBoundary,
					"& input::placeholder": {
						fontSize: "12px",
					},
				}}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				endAdornment={
					<InputAdornment position="end">
						<Search fontSize="small" />
					</InputAdornment>
				}
			/>
		</FormControl>
	);
}
export { PopOver, ActionMenu, Button, Checkbox, DataNotFound, DateTimePicker, DatePicker, DetailCard, Dialog, Dropdown, GenericTable, Input, Loader, SearchDropdown, AddRemove, TimePicker, Switcher };

function generateDetailRows(data) {
	return data?.map(({ label, value }, i) => {
		return (
			<div className="detail_row" key={i}>
				<div className="detail_row_title">{label}</div>
				<div className="detail_row_value">{value}</div>
			</div>
		);
	});
}

const tCell = (text = "-", color = "", cursor = "", onClick = () => {}, i = -1) => {
	return (
		<Typography
			sx={{
				...textClasses.t13n,
				textAlign: "center",
				color,
				cursor,
				minWidth: "7rem",
				maxWidth: "20rem",
				wordWrap: "break-word",
			}}
			onClick={(e) => onClick(e, i)}
		>
			{typeof text == "object" ? text : text.toString()}
			{text == "Action" ? <ArrowDownIcon /> : null}
		</Typography>
	);
};

function toastMessage(isSuccess, message, options = {}) {
	if (isSuccess === "loading") {
		return toast.loading(message, options);
	}
	return isSuccess ? toast.success(message, options) : toast.error(message, options);
}

function dismissToast(id) {
	return toast.dismiss(id);
}

export { generateDetailRows, tCell, toastMessage, dismissToast };

export default function UploadFileButton({
	onChange,
	onChangeClear,
	color = "var(--primary-color)",
	bgColor = "transparent",
	disabled = false,
	opacity = "1",
	allowedExtensions,
	fileName,
	border = "1px solid  var(--primary-color)",
	width = "100%",
	icon = <UploadIcon />,
	buttonText = "Upload a file",
	asterisk = "",
	error = "",
}) {
	const handleClear = () => {
		onChangeClear();
	};

	const handleClick = () => {
		fakeFileInput((fileData) => {
			onChange(fileData.get("file"));
		}, allowedExtensions);
	};
	return (
		<div className="upload-file-button-2-container">
			<button
				className={`btn ${asterisk} cursor-pointer`}
				type="button"
				style={{
					backgroundColor: bgColor,
					border: border,
					color: color,
					borderRadius: "10px",
					width,
					opacity,
					fontSize: "var(--project-large-font)",
				}}
				onClick={handleClick}
			>
				<span style={{ maxWidth: "200px", wordWrap: "break-word" }}>{fileName || buttonText}</span>
				<div
					style={{ zIndex: `${fileName ? 50 : 0}` }}
					onClick={(e) => {
						e.stopPropagation();
						fileName ? handleClear() : handleClick();
					}}
				>
					{fileName ? <CloseIcon /> : icon}
				</div>
			</button>
			<span style={{ color: "var(--red-color)", fontSize: "12px" }}>{error}</span>
		</div>
	);
}

export function ColorPicker({ onChange, name = "favcolor", value }) {
	const [color, setColor] = useState("#ff7200");

	const handleColorChange = (event) => {
		setColor(event.target.value);
		onChange(event.target.value, event);
	};
	return (
		<>
			<div className="color-picker-container">
				<span className="input-text">{value || color}</span>
				<div className="color-picker-btn-container">
					<button className="btn" type="button">
						<img style={{ width: "25px", height: "25px" }} src={colorPicker} alt="color-picker" />
					</button>
					<input type="color" id="favcolor" name={name} value={value || color} onChange={handleColorChange} />
				</div>
			</div>
		</>
	);
}

function AddRemove({ list = [], filterMethod, onAdd, onRemove, index, outerIndex }) {
	const disableAdd = list.filter(filterMethod).length >= 2;
	const length = list.length;
	return (
		<div className="d-flex c_gap_h">
			{length > 1 ? (
				<div className="remove_circ_md" onClick={() => onRemove(index, outerIndex)}>
					<i className="fa fa-times-circle"></i>
				</div>
			) : null}
			{outerIndex == length - 1 ? (
				<div
					className="add_circ_md"
					onClick={(e) => {
						if (!disableAdd) onAdd(index, outerIndex);
						return;
					}}
					style={{
						cursor: disableAdd ? "not-allowed" : "pointer",
					}}
				>
					<i className="fa fa-plus-circle"></i>
				</div>
			) : null}
		</div>
	);
}
