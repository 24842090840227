import React, { useEffect, useRef, useState } from "react";

import { Dropdown, GenericTable, Input, toastMessage, Button, tCell, ActionMenu, Dialog } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import { useCheckpoints, useOrgs, useProductCategories, useProducts, useUserDetail, useUserPermissions, useUsers } from "../../services/hooks";
import { fakeFileInput, formatDate } from "../../services/functions";
import { useAddProductMutation, useDeleteProductMutation, useUpdateProductMutation, useUploadBulkProductMutation } from "../../api/vmsSlice";
import { useGetWorkflowListMutation } from "../../api/vmsSlice";
import { Navigate } from "react-router-dom/dist";
import { useUploadExcelMutation } from "../../api/api";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { Grid } from "@mui/material";
import { GenericstatsV2 } from "../utils/Genericstats";

const SAMPLE_EXCEL_PATH = "/sample_excels/prod_sample.xlsx";
const EXCEL_TYPE = "product";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const Product = () => {
	const { userPermissions, isFetched } = useUserPermissions();

	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const [isModal, setIsModal] = useState(false);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const onModalOpen = () => setIsModal(true);
	const updateDataRef = useRef(null);
	const [filterApplied, setFilterApplied] = useState(false);
	const filterFields = [
		{
			inputType: "textfield",
			id: "1",
			name: "productName",
			type: "text",
			label: "ProductName",
			placeholder: "ProductName",
		},
		{
			inputType: "textfield",
			id: "2",
			name: "productCategory",
			type: "text",
			label: "Categories",
			placeholder: "Categories",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}

	const filterRef = useRef(defaultFilter);
	const { page_no = 1, pagesize = 10 } = filterRef.current;

	const { products, count, isLoading, getProducts } = useProducts(filterRef.current);

	const [handleDelete] = useDeleteProductMutation();

	let actionHandlers = {};
	if (userPermissions?.updateProduct?.value) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = products[id];
			setIsModal(true);
		};
	}
	if (userPermissions?.deleteProduct?.value) {
		actionHandlers.delete = async (id) => {
			// console.log(id, "delete");
			const data = products[id];
			const payload = { deleteId: data._id };
			const {
				data: { error, message },
			} = await handleDelete(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				onUpdateSuccess();
				toastMessage(true, message);
			}
		};
	}
	let header = ["S.no", "Product Name", "Product Category", "Priority", "Per Day Target"];

	if (userDetail?.userType == "superAdmin") {
		header.push("Org Name");
	}
	if (userPermissions?.updateProduct?.value || userPermissions?.deleteProduct?.value) {
		header.push("Action");
	}

	const rows =
		products?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.productName), tCell(d.productCategory), tCell(d.priority), tCell(d.perDayTarget));

			if (userDetail?.userType == "superAdmin") {
				row.push(tCell(d.orgName));
			}
			// if permission
			if (userPermissions?.updateProduct?.value || userPermissions?.deleteProduct?.value) {
				row.push(<div style={{ paddingLeft: "0.5rem" }}>
					<ActionMenu id={i} handlers={actionHandlers} />
				</div>);
			}
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getProducts(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getProducts(filterRef.current);
	};
	const onUpdateSuccess = () => {
		getProducts(filterRef.current);
	};

	const [handleUploadExcel] = useUploadBulkProductMutation();
	const onBulkExcelUpload = () => {
		fakeFileInput(async (formData) => {
			formData.append("type", EXCEL_TYPE);
			// const payload = {
			// 	file,
			// 	type: EXCEL_TYPE,
			// };
			const {
				data: { error, message },
			} = await handleUploadExcel(formData);
			if (error) {
				toastMessage(false, message);
			} else {
				toastMessage(true, message);
				onUpdateSuccess();
			}
		});
	};
	const handleFilterChange = (filter) => {
		if (filter?.productCategoryId != "" && Object.keys(filter).length > 0) {
			// console.log("filter on change-device", filter);
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getProducts(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.productCategoryId === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log("without-filter on change-device", filter);
			getProducts(defaultFilter);
			setFilterApplied(false);
		}
	};
	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getProduct?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<Grid container spacing="0.5rem">
				<Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
					<h1 className="title">Product</h1>
				</Grid>
				<Grid item xl={6} lg={6} md={7} sm={12} xs={12}>
					<Grid container spacing="0.5rem">
						{userPermissions?.addProduct?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onModalOpen} text="Add Product" style={buttonClasses.lynkitOrangeEmpty} />
							</Grid>
						) : null}
						{userPermissions?.addProduct?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onBulkExcelUpload} text="Bulk Upload" style={buttonClasses.lynkitOrangeFill} />
							</Grid>
						) : null}
						{userPermissions?.addProduct?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<a href={SAMPLE_EXCEL_PATH} download>
									<Button text="Download Sample" style={buttonClasses.lynkitBlackFill} />
								</a>
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>

			<hr className="bgg" />
			<GenericstatsV2 statsFor={'product'} />
			<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			<GenericTable header={header} rows={rows} pageCount={count} pageNo={page_no} limit={pagesize} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} isLoading={isLoading} />

			{isModal && <AddProduct open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
		</div>
	);
};

export default Product;

const defaultForm = {
	productName: "", // required
	updateId: undefined, // for update case
	productCategoryId: "", // required
	perDayTarget: "",
	priority: "",
	orgId: undefined,
	checkpoints: [],
	users: [],
	// approvalId: "",
	prefix: "",
	productId: "",
	// description: "Base fruit to be distilled",
};

function fillUpdateData(data) {
	return {
		productName: data.productName,
		updateId: data._id,
		productCategoryId: data.productCategoryId,
		perDayTarget: data.perDayTarget,
		priority: data.priority,
		orgId: data.orgId,
		checkpoints: data.checkpoints || [],
		users: data.users || [],
		workFlowId: data.workFlowId || "",
		// approvalId: data.approvalId || "",
		prefix: data.prefix || "",
		productId: data.productId || "",
	};
}

function AddProduct({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});
	const [workflowListing, setWorkFlowListing] = useState([]);
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const orgValue = orgOptions.find((_) => _.value == formData.orgId);

	// const { userOptions, getUsers } = useUsers();
	// let userValues = userOptions?.filter((chk) => formData.users?.find((fchk) => fchk == chk.value));
	// console.log(userValues, formData.users);
	const filterRef = useRef({ orgId: updateData?.orgId, type: "bay" });
	const { checkpointOptions } = useCheckpoints(filterRef.current);
	let checkpointValues = checkpointOptions?.filter((chk) => formData.checkpoints?.find((fchk) => fchk == chk.value));

	const { options: productCategoryOptions, getProdCats } = useProductCategories();
	const productCategoryValue = productCategoryOptions.find((_) => _.value == formData.productCategoryId);

	const [getWorkflowListTrigger, { isLoading }] = useGetWorkflowListMutation();

	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const onDropdownChange = (option, name) => {
		let value = Array.isArray(option) ? option.map((_) => _.value) : option?.value || "";
		handleInput({ target: { name, value } });
	};

	function fetchByOrgId(orgId) {
		// getUsers({ orgId });
		getProdCats({ orgId });
		filterRef.current = { ...filterRef.current, orgId };
	}
	const fetchWorkFlowListing = (payload = {}) => {
		getWorkflowListTrigger(payload)
			.unwrap()
			.then((res) => {
				// console.log('res==', res)
				if (res?.data) {
					const option = res.data.map((_) => ({ label: _.name, value: _._id }));
					setWorkFlowListing(option);
					// setCount(res.count)
					// setFilter((previousValue) => ({ ...previousValue, count: res.count }));
				}
				if (res.error == true) {
					// toastMessage(false, res.message);
					setWorkFlowListing([]);
				}
			})
			.catch((err) => {
				// toastMessage(false, err.message);
				setWorkFlowListing([]);
			});
	};

	// const [getApprovalConfigsTrigger] = useGetApprovalConfigMutation({});
	// const [approvalConfigOptions, setApprovalConfigOptions] = useState([]);

	// const fetchApprovalConfigurations = (payload = {}) => {
	// 	getApprovalConfigsTrigger(payload)
	// 		.unwrap()
	// 		.then((res) => {
	// 			if (res.data) {
	// 				const options = res.data.map((_) => ({ label: _.module, value: _._id }));
	// 				setApprovalConfigOptions(options);
	// 			} else {
	// 				setApprovalConfigOptions([]);
	// 			}
	// 		})
	// 		.catch((err) => setApprovalConfigOptions([]));
	// };

	useEffect(() => {
		// console.log("hey");
		fetchByOrgId(formData.orgId);
		fetchWorkFlowListing(formData.orgId ? { orgId: formData.orgId } : {});
		// fetchApprovalConfigurations(formData.orgId ? { orgId: formData.orgId } : {});
	}, [formData.orgId]);
	const [handleAdd, { isLoading: isAdding }] = useAddProductMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateProductMutation();

	const handleFormSubmit = async () => {
		const payload = { ...formData, orgName: orgValue?.label };
		// console.log(payload);
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};

	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";
		if (!formData.productCategoryId) error.productCategoryId = "Product Category is required";
		if (!formData.productName) error.productName = "Product Name is required";
		if (!formData.priority) error.priority = "Priority is required";
		if (!formData.perDayTarget) error.perDayTarget = "Per Day Target is required";
		// if (!formData.workFlowId) error.workFlowId = "Workflow is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		setError({});
		onClose();
	};
	return (
		<Dialog size="sm" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={updateData ? "Update Product" : "Add Product"} isSubmitDisabled={isAdding || isUpdating}>
			<form className="form-inputs">
				{userDetail?.userType == "superAdmin" ? (
					<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} error={error.orgId} required />
				) : null}
				{/* <Dropdown id="users" name="users" label="Users" onChange={onDropdownChange} options={userOptions} value={userValues} required multiple error={error.users} /> */}
				<Dropdown
					id="checkpoints"
					name="checkpoints"
					label="Bay Checkpoints"
					onChange={onDropdownChange}
					options={checkpointOptions}
					value={checkpointValues}
					multiple
					error={error.checkpoints}
				/>
				<Dropdown
					id="productCategoryId"
					name="productCategoryId"
					label="Product Category"
					onChange={onDropdownChange}
					options={productCategoryOptions}
					value={productCategoryValue}
					required
					error={error.productCategoryId}
				/>
				<Input label="Product Name" placeholder="Enter Product Name" name="productName" value={formData.productName} onChange={handleInput} required error={error.productName} />
				<Input label="Priority" placeholder="Enter Priority" name="priority" value={formData.priority} onChange={handleInput} required error={error.priority} />
				<Input label="Per Day Target" placeholder="Enter Per Day Target" name="perDayTarget" value={formData.perDayTarget} onChange={handleInput} error={error.perDayTarget} required />
				<Dropdown
					id="workFlowId"
					name="workFlowId"
					label="WorkFlow"
					onChange={onDropdownChange}
					options={workflowListing}
					value={formData?.workFlowId ? workflowListing.filter((ele) => ele.value === formData.workFlowId)[0] : null}
					// error={error.workFlowId}
				// (workflowListing.filter((ele)=> ele.id === formData.workFlowId))[0]
				// required
				// error={error.productCategoryId}
				/>
				{/* <Dropdown
					id={"approvalId"}
					name={"approvalId"}
					value={formData.approvalId ? approvalConfigOptions.find((ele) => ele.value == formData.approvalId) : null}
					options={approvalConfigOptions}
					label={"Approval Configuration"}
					onChange={onDropdownChange}
				/> */}
				<Input label="Prefix" placeholder="Enter Prefix" name="prefix" value={formData.prefix} onChange={handleInput} />
				<Input label="Product Id" placeholder="Enter Product Id" name="productId" value={formData.productId} onChange={handleInput} />
			</form>
		</Dialog>
	);
}
