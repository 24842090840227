import React, { useEffect, useRef, useState } from "react";

import { useCheckpoints, useOrgs, useRoles, useUserDetail, useAllPermissionSet, useDynamicModules } from "../../services/hooks";
import { valid_email, valid_mobile, valid_password } from "../../services/validation";

import { Checkbox, Dialog, Dropdown, Input, Loader } from "../utils";
import { toastMessage } from "../utils";
import { capitalizeCamelCase } from "../../services/functions";
import { useAddUserMutation, useUpdateUserMutation } from "../../api/userSlice";
import "./AddUser.css";

const defaultForm = {
	name: "",
	email: "",
	mobile: "",
	password: "",
	confirmPassword: "",
	permissions: {},
	userRole: "",
	userRoleId: "",
	orgId: "",
	orgName: "",
};
function fillUpdateData(data) {
	let permission = {};
	if (data?.userPermissions.length > 0) {
		data?.userPermissions.forEach((ele) => {
			permission[ele.module || "org"] = ele.permissions;
		});
	}
	let { org, ...rest } = permission;
	return {
		name: data?.name,
		email: data?.email,
		mobile: data?.mobile,
		orgId: data?.orgId,
		orgName: data?.orgName,
		permissions: rest,
		userRole: data.userRole,
		userRoleId: data.userRoleId,
	};
}
const permObj = { dependancy: [], strict: false, value: true };
function makeCheckpointPerms(checkpoints = []) {
	// console.log("checkpoints", checkpoints);
	return checkpoints.reduce((res, curr) => {
		const key = `checkpoint-${curr._id}`;
		const listConfigPerms = curr.listingConfig?.reduce((res, curr) => {
			res[`${key}-${curr.type}`] = permObj;
			return res;
		}, {});

		// adding extra permissions based on a checkpoint config
		let additionalPerms = {};
		if (curr.assetConfig && Object.keys(curr.assetConfig).length) {
			for (let permType in curr.assetConfig) {
				//check if the particular permission is enabled at the current checkpoint
				if (curr.assetConfig[permType]) {
					additionalPerms[`${key}-${permType}`] = { ...permObj, dependancy: [permType], strict: true };
				}
			}
		}

		res[curr._id] = {
			[key]: permObj,
			key,
			permObj,
			[`${key}-manualEntry`]: permObj,
			[`${key}-viewSubmit`]: permObj,
			[`${key}-viewDetail`]: permObj,
			[`${key}-viewListing`]: permObj,
			...(listConfigPerms || {}),
			...additionalPerms,
		};
		// console.log(additionalPerms)
		return res;
	}, {});
}

function AddUser({ onClose, open, updateData, onUpdateSuccess }) {
	const [handleAdd, { isLoading: isAdding }] = useAddUserMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateUserMutation();

	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	useEffect(() => {
		if (!formData.orgId) {
			clearCheckpointData();
			return;
		}
		roleFitlerRef.current = { orgId: formData.orgId };
		getRoles(roleFitlerRef.current);

		checkpointFilterRef.current = { orgId: formData.orgId };
		getCheckpoints(checkpointFilterRef.current);

		getDynamicModules({ orgId: formData.orgId });
	}, [formData.orgId]);
	const { userDetail } = useUserDetail();
	useEffect(() => {
		if (!userDetail) return;
		if (userDetail.userType == "superAdmin") return;

		if (userDetail.userType != "superAdmin" && roleOptions.length) return;
		roleFitlerRef.current = { orgId: userDetail.orgId };
		getRoles(roleFitlerRef.current);
	}, [userDetail]);

	const [error, setError] = useState({});

	let checkpointFilterRef = useRef({});
	const { checkpoints, getCheckpoints, isLoading: isChkptLoading, clearCheckpointData } = useCheckpoints();
	const checkpointsPerms = makeCheckpointPerms(checkpoints);

	const { orgOptions } = useOrgs();
	const orgValue = orgOptions.find((_) => _.value == formData.orgId) || null;

	const roleFitlerRef = useRef({});
	const { roleOptions, getRoles, isLoading: roleLoading } = useRoles();
	const roleValue = roleOptions.find((_) => _.value == formData.userRole) || null;

	const { permissions: permissionSetPerms, isLoading: isPermLoading } = useAllPermissionSet();
	const { dynamicModules, getDynamicModules } = useDynamicModules(null, "dynamicModuleAddUser");

	useEffect(() => {
		if (!userDetail) return;
		if (userDetail.userType == "superAdmin") return;

		if (userDetail.userType != "superAdmin" && dynamicModules.length) return;
		getDynamicModules({orgId: userDetail.orgId });
	}, [userDetail]);

	const dynamicModuleDefaultPerms = dynamicModules.reduce((res, curr) => {
		res = {
			...res,
			[`module-add${curr.moduleName.replaceAll(" ", "")}`]: {
				...permObj,
			},
			[`module-get${curr.moduleName.replaceAll(" ", "")}`]: {
				...permObj,
			},
			[`module-update${curr.moduleName.replaceAll(" ", "")}`]: {
				...permObj,
			},
			[`module-delete${curr.moduleName.replaceAll(" ", "")}`]: {
				...permObj,
			},
		};
		return res;
	}, {});

	let permissions = { ...permissionSetPerms, vms: { ...permissionSetPerms.vms, ...dynamicModuleDefaultPerms } };
	// console.log(permissions);
	const handleInput = (e) => {
		let { name, value, checked, type } = e.target;
		if (name == "mobile" && isNaN(value)) return;
		setFormData((old) => ({ ...old, [name]: value }));
	};
	const handleCheckAllPerm = (e) => {
		const { checked } = e.target;
		setFormData((o) => ({
			...o,
			permissions: checked ? { ...permissions } : {},
		}));
	};
	const handlePermChange = (e, type) => {
		const { name, checked } = e.target;
		setFormData((old) => {
			let newData = JSON.parse(JSON.stringify(old));
			if (checked) {
				// add permission
				newData.permissions[type] = newData.permissions[type] || {};
				newData.permissions[type][name] = permissions[type][name];
			} else {
				// remove this permission
				delete newData.permissions[type][name];
			}
			return newData;
		});
	};
	const handleCheckpointPermChange = (e, type) => {
		const { name, checked } = e.target;
		setFormData((old) => {
			let newData = JSON.parse(JSON.stringify(old));
			if (checked) {
				newData.permissions.vms = { ...(newData.permissions.vms || {}), [name]: checkpointsPerms[type][name] };
			} else {
				delete newData.permissions.vms[name];
			}
			return newData;
		});
	};
	const handleRoleCheckpointPerms = (checkpointIds = []) => {
		const roleChkPts = checkpoints
			.filter((chk) => checkpointIds.includes(chk._id))
			.reduce((res, curr) => {
				const { _id, detailApi, listingConfig, api, checkoutConfig } = curr;
				const key = `checkpoint-${_id}`;
				res[key] = permObj;

				if (api) {
					res[`${key}-manualEntry`] = permObj;
				}
				if (checkoutConfig?.api) {
					res[`${key}-viewSubmit`] = permObj;
				}
				if (detailApi) {
					res[`${key}-viewDetail`] = permObj;
				}
				if (listingConfig?.length) {
					res[`${key}-viewListing`] = permObj;
					listingConfig.forEach(({ type }) => {
						res[`${key}-${type}`] = permObj;
					});
				}

				return res;
			}, {});

		setFormData((old) => {
			const newData = JSON.parse(JSON.stringify(old));
			let newVmsPerms = {};
			// filter out checkpoint perms
			Object.keys(newData.permissions.vms || {}).map((key) => {
				if (!key.includes("checkpoint")) {
					newVmsPerms[key] = newData.permissions.vms[key];
				}
			});

			// set role based checkpoint perms
			Object.keys(roleChkPts).forEach((key) => {
				newVmsPerms[key] = permObj;
			});
			newData.permissions.vms = newVmsPerms;
			return newData;
		});
	};

	const onDropdownChange = (option, name) => {
		handleInput({ target: { name, value: option?.value || "" } });
		if (name == "userRole") {
			handleInput({ target: { name: "userRoleId", value: option?.data?._id || "" } });
			handleRoleCheckpointPerms(option?.data?.checkpoints);
		}
	};

	const [passwordType, setPasswordType] = useState("password");
	const changeType = () => {
		if (passwordType === "password") {
			setPasswordType("string");
		} else {
			setPasswordType("password");
		}
	};
	const [confirmPasswordType, setConfirmMPasswordType] = useState("password");
	const changeConfirmType = () => {
		if (confirmPasswordType === "password") {
			setConfirmMPasswordType("string");
		} else {
			setConfirmMPasswordType("password");
		}
	};

	const handleFormSubmit = async () => {
		const payload = {
			...formData,
			orgId: userDetail.userType == "superAdmin" ? formData.orgId : userDetail.orgId,
			userType: userDetail.userType == "superAdmin" ? "admin" : userDetail.userType == "admin" ? "user" : undefined,
			project: userDetail.userType == "superAdmin" ? "lynkid" : undefined,
			module: userDetail.userType == "admin" ? userDetail?.module || "Default" : "Default",
			orgName: orgValue?.label,
			userId: updateData?._id || undefined,
		};
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};

	const handleFormValidation = () => {
		let error = {};
		if (!formData.name) error.name = "Name is required";

		if (!formData.email) error.email = "Email is required";
		else if (!valid_email(formData.email)) error.email = "Enter a valid email";

		if (!formData.mobile) error.mobile = "Mobile is required";
		else if (!valid_mobile(formData.mobile)) error.mobile = "Enter a valid mobile number";

		if (!updateData) {
			if (!formData.password) error.password = "Password is required";
			else if (!valid_password(formData.password))
				error.password = "Password should contain minimum 8 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.";
			if (!formData.confirmPassword) error.confirmPassword = "Please enter your password again";
			else if (formData.confirmPassword !== formData.password) error.confirmPassword = "Passwords do not match";
		}

		if (!formData.userRole) error.userRole = "User role is required";
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};

	const handleClose = () => {
		setFormData(defaultForm);
		setError({});
		onClose();
	};

	// create permission inputs
	let perms = [];
	for (let permModule in permissions) {
		for (let perm in permissions[permModule]) {
			if (permissions[permModule][perm].value) {
				perms.push(
					<Checkbox
						key={`${perm}_${permModule}`}
						label={capitalizeCamelCase(perm)}
						id={perm}
						name={perm}
						checked={formData.permissions[permModule] ? !!formData.permissions[permModule][perm] : false}
						onChange={(e) => {
							handlePermChange(e, permModule);
						}}
					/>
				);
			}
		}
	}

	// create checkpoint permission inputs
	let chkPerms = [];
	for (let id in checkpointsPerms) {
		const { key, permObj } = checkpointsPerms[id];
		const { checkpointType: type, displayName } = checkpoints.find((_) => _._id == id);

		let perms = [];
		for (let key in checkpointsPerms[id]) {
			if (key != "key" && key != "permObj" && key.split("-").length == 3 && formData.permissions.vms && formData.permissions.vms[checkpointsPerms[id].key]) {
				perms.push(
					<Checkbox
						key={key}
						label={capitalizeCamelCase(key.split("-")[2])}
						id={key}
						name={key}
						checked={formData.permissions.vms ? !!formData.permissions.vms[key] : false}
						onChange={(e) => {
							handleCheckpointPermChange(e, id);
						}}
					/>
				);
			}
		}
		chkPerms.push(
			<div key={id} className="checkpoint-perm-wrap">
				<Checkbox
					key={key}
					label={capitalizeCamelCase(displayName)}
					id={key}
					name={key}
					checked={formData.permissions.vms ? !!formData.permissions.vms[key] : false}
					onChange={(e) => {
						handleCheckpointPermChange(e, id);
					}}
				/>
				<div className="checkpoint-perm-wrap-inner">{perms}</div>
			</div>
		);
	}

	let areAllPermChecked = true;
	for (let permModule in permissions) {
		if (Object.keys(permissions[permModule]).length != Object.keys(formData.permissions[permModule] || {}).length) {
			areAllPermChecked = false;
		}
	}

	return (
		<Dialog size="md" open={open} title={updateData ? "Update User" : "Add User"} handleClose={handleClose} handleSubmit={handleFormValidation} isSubmitDisabled={isAdding || isUpdating}>
			<form className="">
				<div className="form-inputs mt-2">
					<Input label="Name" placeholder="Enter Name" name="name" id="name" value={formData.name} onChange={handleInput} error={error.name} required />
					<Input
						readOnly={updateData ? true : false}
						type="email"
						label="Email"
						placeholder="Enter Email"
						name="email"
						id="email"
						value={formData.email}
						onChange={handleInput}
						error={error.email}
						required
					/>
					<Input type="tel" label="Mobile" placeholder="Enter Mobile" name="mobile" id="mobile" value={formData.mobile} onChange={handleInput} error={error.mobile} required />
					{!updateData && (
						<Input
							type={passwordType}
							label="Password"
							placeholder="Enter Password"
							onChange={handleInput}
							name="password"
							id="password"
							value={formData.password}
							adornment={{
								is: true,
								onClick: changeType,
								type: passwordType === "password" ? "eye" : "eyeClose",
							}}
							required
							error={error.password}
						/>
					)}
					{!updateData && (
						<Input
							label="Confirm Password"
							placeholder="Confirm Password"
							onChange={handleInput}
							name="confirmPassword"
							id="confirmPassword"
							value={formData.confirmPassword}
							type={confirmPasswordType}
							adornment={{
								is: true,
								onClick: changeConfirmType,
								type: confirmPasswordType === "password" ? "eye" : "eyeClose",
							}}
							required
							error={error.confirmPassword}
						/>
					)}

					{userDetail?.userType == "superAdmin" ? (
						<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} required error={error.orgId} />
					) : null}
					<Dropdown id="userRole" name="userRole" label="User Role" onChange={onDropdownChange} options={roleOptions} value={roleValue} required error={error.userRole} />
				</div>

				{isChkptLoading ? (
					<Loader size="1.5rem" />
				) : chkPerms.length ? (
					<>
						<div className="flex-row-sb selectAllPerm">
							<div style={{ fontWeight: "600" }}>Checkpoint Permissions</div>
						</div>
						<div className="checkpoint-perm-wrap-outer">{chkPerms}</div>
					</>
				) : null}

				<div className="flex-row-sb selectAllPerm">
					<div style={{ fontWeight: "600" }}>Permissions</div>
					<Checkbox label="Select All" id="selectAllPerm" name="selectAllPerm" checked={areAllPermChecked} onChange={handleCheckAllPerm} />
				</div>
				{isPermLoading ? <Loader size="1.5rem" /> : <div className="perm_wrap">{perms}</div>}
			</form>
		</Dialog>
	);
}

export default AddUser;
